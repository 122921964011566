/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">{{ typeName }}</h1>

    <div class="control add-global-controls" v-if="type === '0'">
      <b-button
        type="is-primary"
        class="add-articles-special-margin"
        @click="openKardex()"
        >Ver movimientos en almacén</b-button
      >
    </div>

    <div class="invoice-add-item">
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Comprador (Opc)"
      >
        <b-select
          placeholder="Ejemplo: Sin comprador"
          icon="account-tie"
          expanded
          v-model="newPurchaseOrder.COMPRADOR"
          :disabled="type !== '0' || blockEditGeneralValues"
        >
          <option :value="undefined">Sin comprador</option>
          <option
            :value="singleEmployee._id"
            v-for="singleEmployee in employees"
            :key="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
            {{ " " }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four required-input"
        label-position="inside"
        label="Proveedor (Req)"
      >
        <b-autocomplete
          placeholder="Proveedor"
          :data="autoCompleteProviders(provName)"
          v-model="provName"
          field="NOMBRE_PRV"
          autocomplete
          icon="earth"
          open-on-focus
          :disabled="type !== '0' || blockEditGeneralValues"
          expanded
          clearable
          @select="
            (option) => {
              newPurchaseOrder.PRV = option;
              resetProviderPick();
            }
          "
          check-infinite-scroll
        >
          <template #empty>No se encontraron proveedores</template>
        </b-autocomplete>
        <!-- <b-select
          placeholder="Proveedor"
          icon="earth"
          v-model="newPurchaseOrder.PRV"
          :disabled="type !== '0' || blockEditGeneralValues"
          expanded
          @input="resetProviderPick()"
        >
          <option value="" disabled>Seleccione un proveedor</option>
          <option
            v-for="provider in providers"
            :value="provider"
            :key="provider.NOMBRE_PRV"
          >
            {{ provider.NOMBRE_PRV }}
          </option>
        </b-select> -->
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Fecha (Req)"
      >
        <b-datepicker
          required
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de creación"
          icon="calendar-today"
          v-model="newPurchaseOrder.creationDate"
          :disabled="type !== '0' || blockEditGeneralValues"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Clave interna (Opc)"
        expanded
      >
        <b-field expanded>
          <b-input
            placeholder="123ABC"
            expanded
            icon="clipboard"
            v-model="newPurchaseOrder.NUM_INTERNO"
            :disabled="type !== '0' || blockEditGeneralValues"
          />
        </b-field>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four required-input"
        label-position="inside"
        label="Almacen (Req)"
      >
        <b-select
          placeholder="Almacen"
          icon="warehouse"
          v-model="newPurchaseOrder.ALMACEN"
          :disabled="type !== '0' || blockEditGeneralValues"
          expanded
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Número de pedido del proveedor (Opc)"
      >
        <b-input
          placeholder="123ABCD"
          expanded
          icon="clipboard-outline"
          v-model="NUM_INTERNO_PROV"
          :disabled="type !== '0'"
        />
      </b-field>
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Fecha prevista de entrega (Opc)"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de creación"
          icon="calendar-today"
          v-model="FECHA_PRE"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </div>

    <section ref="requestOrder">
      <div class="invoice-box">
        <div class="invoice-upper-part">
          <div class="invoice-logo"></div>
          <div class="invoice-information">
            Folio: {{ newPurchaseOrder.CLAVE_PED }}
            <br />
            Fecha de creación:
            {{ moment(newPurchaseOrder.creationDate).format("DD-MM-YYYY") }}
          </div>
        </div>

        <div class="invoice-company-info-part">
          <div class="invoice-company" v-if="newPurchaseOrder.PRV">
            <p v-if="newPurchaseOrder.PRV && newPurchaseOrder.PRV.NOMBRE_PRV">
              {{ newPurchaseOrder.PRV.NOMBRE_PRV }}<br />
            </p>
            <p v-if="newPurchaseOrder.PRV && newPurchaseOrder.PRV.DOMICIL_PRV">
              {{ newPurchaseOrder.PRV.DOMICIL_PRV }}<br />
            </p>
            <p
              v-if="
                newPurchaseOrder.PRV &&
                newPurchaseOrder.PRV.CLAVE_ESTADO &&
                newPurchaseOrder.PRV.CP_PRV
              "
            >
              {{ newPurchaseOrder.PRV.CLAVE_ESTADO }},{{
                newPurchaseOrder.PRV.CP_PRV
              }}
              <br />
            </p>
          </div>
          <div class="invoice-company-person" v-if="newPurchaseOrder.PRV"></div>
        </div>

        <div class="invoice-payment-info-part">
          <div class="invoice-payment-type-left">
            <strong>Observaciones</strong>
          </div>
          <div class="invoice-payment-type-right"></div>
        </div>

        <div class="invoice-payment-type-info">
          <div class="invoice-payment-type-info-left invoice-observation">
            <b-input
              type="textarea"
              :disabled="type !== '0' || blockEditGeneralValues"
              v-model="newPurchaseOrder.OBSERVA_PED"
            ></b-input>
          </div>
          <div class="invoice-payment-type-info-right"></div>
        </div>

        <h3 class="purchase-order-table-title">Productos en pedido</h3>
        <div class="table-header-left">
          Peso total: <b>{{ getTotalWeight() | weight }}</b>
        </div>
        <div class="table-header-left">
          Volumen total: <b>{{ getTotalVolumen() | volume }}</b>
        </div>

        <div class="control add-global-controls" v-if="type === '0'">
          <b-button
            type="is-primary"
            @click="openAddArticlesSameWord()"
            v-if="type === '0'"
            >Agregar articulos con misma palabra al pedido</b-button
          >
          <b-button
            type="is-primary"
            @click="openAddMultipleArticles()"
            :disabled="checkDisable"
            >Seleccionar multiples artículos</b-button
          >
          <b-button
            type="is-primary"
            class="add-articles-special-margin"
            @click="openAddShippingCost(newPurchaseOrder)"
            :disabled="checkDisableItems"
            >Agregar costo de flete</b-button
          >
          <b-button
            type="is-primary"
            class="add-articles-special-margin"
            @click="openAddGlobalCost(newPurchaseOrder)"
            :disabled="checkDisableItems"
            >Agregar indirectos globales</b-button
          >
          <b-button
            type="is-primary"
            class="add-articles-special-margin"
            @click="openAddGlobalDiscount(newPurchaseOrder)"
            :disabled="checkDisableItems"
            >Agregar descuentos globales</b-button
          >
          <b-button
            type="is-primary"
            class="add-articles-special-margin"
            @click="roundQuantities()"
            >Redondear cantidades</b-button
          >
          <b-button
            type="is-primary"
            class="add-articles-special-margin"
            @click="roundQuantitiesSpecial()"
            >Redondear cantidades a 5 o 0</b-button
          >
        </div>

        <div class="global-lists-search no-margin">
          <div class="global-lists-filters-container"></div>
          <div class="global-lists-filters-columns no-margin">
            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <b-checkbox
              v-for="singleColumn in columns"
              v-bind:key="singleColumn.label"
              v-model="singleColumn.display"
              v-if="!singleColumn.hideTable"
            >
              {{ singleColumn.label }}
            </b-checkbox>
          </div>
        </div>

        <b-table
          pagination-position="both"
          :data="newPurchaseOrder.currentItems"
          :striped="true"
          :bordered="true"
          :hoverable="true"
          ref="purchaseOrder"
          paginated
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="asc"
          class="purchase-order-table"
          scrollable
          detailed
          :opened-detailed="defaultOpenedDetails"
        >
          <template #empty>
            <div class="has-text-centered">No hay productos</div>
          </template>

          <template slot-scope="props">
            <b-table-column
              label="Acciones"
              v-if="type !== '1'"
              :visible="columns[0].display"
            >
              <b-field class="invoice-option-select">
                <b-select
                  :key="props.row.RENGLON"
                  placeholder="Opciones"
                  v-model="props.row.selectedOption"
                  @input="handleOptionChange(props.row)"
                  v-if="props.row.article"
                >
                  <option value="empty">Selecciona una opción</option>
                  <option value="deleteItem">Borrar artículo</option>
                  <option value="discount">Editar descuentos</option>
                  <option value="cost">Editar indirectos</option>
                  <option value="restartPrice" v-if="props.row.PRECIO_ORIGINAL">
                    Restablecer costo
                  </option>
                </b-select>
              </b-field>
            </b-table-column>

            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <b-table-column
              label="Artículo"
              v-if="!props.row.article"
              :visible="columns[1].display"
            >
              <div class="invoice-article-div">
                <b-button
                  icon-right="magnify"
                  size="is-small"
                  type="is-primary"
                  class="search-article"
                  @click="openSearchArticle(props.row)"
                >
                </b-button>
              </div>
            </b-table-column>

            <b-table-column
              label="Artículo"
              field="article.DESCRIPCION"
              sortable
              v-if="props.row.article"
              :visible="columns[1].display"
            >
              <span
                v-bind:class="{ 'red-price': getRedPrice(props.row) }"
                v-if="props.row.article"
                >{{ props.row.article.DESCRIPCION }}</span
              >
            </b-table-column>

            <b-table-column
              label="Clave del artículo"
              field="article.CLAVE_ART"
              sortable
              :visible="columns[2].display"
            >
              <span v-if="props.row.article">{{
                props.row.article.CLAVE_ART
              }}</span>
            </b-table-column>

            <b-table-column
              label="Presentación"
              field="PRESENTATION"
              :visible="columns[3].display"
            >
              <b-field class="invoice-option-select" v-if="props.row.article">
                <b-select
                  placeholder="Ejemplo: PIEZA"
                  expanded
                  v-model="props.row.PRESENTACION"
                  v-if="props.row.article"
                  class="special-width-input"
                  @input="updateTotals()"
                >
                  <option value="" disabled>Presentaciones</option>
                  <option value="UB" v-if="!props.row.article.UNIDAD_EMP">
                    Unidad de medida base
                  </option>
                  <option value="UB" v-if="props.row.article.UNIDAD_EMP">
                    {{
                      capitalizeString(props.row.article.UNIDAD_EMP.DESCRIP_MED)
                    }}
                  </option>
                  <option
                    v-for="singlePresentation in props.row.article
                      .PRESENTACIONES"
                    :value="singlePresentation._id"
                    :key="singlePresentation._id"
                  >
                    {{ singlePresentation.NOMBRE }}
                  </option>
                </b-select>
              </b-field>
              <div v-if="!props.row.article">
                <p>No hay información</p>
              </div>
            </b-table-column>

            <b-table-column label="IVA" :visible="columns[4].display">
              {{ getIva(props.row) + "%" }}
            </b-table-column>

            <b-table-column label="IEPS" :visible="columns[5].display">
              {{ getIeps(props.row) + "%" }}
            </b-table-column>

            <b-table-column
              label="Código del proveedor"
              field="article.providerCode"
              sortable
              :visible="columns[6].display"
            >
              <span v-if="getProviderCode(props.row.article)">{{
                getProviderCode(props.row.article)
              }}</span>
              <span v-if="!getProviderCode(props.row.article)">Sin código</span>
            </b-table-column>

            <b-table-column
              label="Costo"
              :visible="columns[7].display && showReception"
            >
              <b-input
                class="special-width-input"
                placeholder="Ejemplo: 100"
                expanded
                v-model="props.row.price"
                @input="updateTotals(true)"
              ></b-input>
            </b-table-column>

            <b-table-column
              label="Indirectos"
              field="costTotal"
              sortable
              :visible="columns[8].display && showReception"
            >
              <span v-if="props.row.article">{{
                props.row.costTotal | money("MXN", 5)
              }}</span>
            </b-table-column>

            <b-table-column
              label="Descuentos totales"
              field="discountTotal"
              sortable
              :visible="columns[9].display && showReception"
            >
              <span v-if="props.row.article">{{
                props.row.discountTotal | money("MXN", 5)
              }}</span>
            </b-table-column>

            <b-table-column
              label="Cantidad total"
              field="quantity"
              sortable
              :visible="columns[10].display"
            >
              <b-input
                :disabled="type !== '0'"
                placeholder="Ejemplo: 0"
                expanded
                v-model="props.row.quantity"
                @input="updateTotals(true)"
                v-if="props.row.article"
              ></b-input>
            </b-table-column>

            <b-table-column
              label="Costo Final"
              field="finalPrice"
              :v-bind="props.row.article"
              sortable
              :visible="columns[11].display && showReception"
            >
              <span v-if="props.row.article">{{
                props.row.finalPrice | money("MXN", 2)
              }}</span>
            </b-table-column>
          </template>
          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <div class="title-expandable-item">
                    <!-- eslint-disable vue/valid-v-for -->
                    <div class="title-expandable-item">
                      <strong
                        >Detalles para almacén
                        <span class="global-form-hint-color">
                          {{ newPurchaseOrder.ALMACEN.CLAVE_ALMACEN }}
                        </span></strong
                      >
                    </div>
                    <div class="purchase-order-detail">
                      <b-field
                        v-if="props.row.article"
                        label="Cantidad de piezas pedidas"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          :disabled="type !== '0' || blockEditGeneralValues"
                          placeholder="Ejemplo: 0"
                          expanded
                          v-model="props.row.quantity"
                          icon="pencil-plus-outline"
                          @input="updateTotals(true)"
                          type="number"
                          step="any"
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Máximo"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="getInventoryMaximum(props.row.article)"
                          icon="warehouse"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Inventario en almacén"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="
                            getInventoryWarehouse(props.row.article, false)
                          "
                          icon="warehouse"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Inventario en almacén de ventas"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="
                            getInventoryWarehouse(props.row.article, true)
                          "
                          icon="warehouse"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Piezas por surtir"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          icon="numeric"
                          :value="getItemBackOrder(props.row)"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Piezas surtidas"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          value="0"
                          icon="numeric"
                          v-model="props.row.quantityReceived"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Peso del artículo"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="props.row.article.CANT_PESO | weight"
                          icon="arrow-up"
                          disabled
                          v-if="props.row.article.CANT_PESO"
                        ></b-input>
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="0 | weight"
                          icon="arrow-up"
                          disabled
                          v-if="!props.row.article.CANT_PESO"
                        ></b-input>
                      </b-field>
                    </div>

                    <!-- eslint-enable -->
                  </div>
                </div>
              </div>
            </article>
          </template>
        </b-table>

        <div v-if="showReception">
          <div class="invoice-payment-total-info-costs">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Subtotal:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.subtotal | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Indirectos:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.costs | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Descuentos:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.discounts | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Flete:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.shippingQuantity | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>IVA:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.iva | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>IEPS:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.ieps | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Total:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newPurchaseOrder.total | money("MXN", 2)
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="add-global-controls" v-if="type === '0'">
      <b-button type="is-success" @click="saveRequestOrder()"
        >Editar pedido</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="type !== '0'">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import moment from 'moment'
import html2pdf from "html2pdf.js";
import moment from "moment";
import AddShippingCost from "./purchase-orders-actions/AddShippingCost";
import AddDiscount from "./purchase-orders-actions/AddDiscount";
import AddCost from "./purchase-orders-actions/AddCost";
import AddGlobalCost from "./purchase-orders-actions/AddGlobalCost";
import AddGlobalDiscount from "./purchase-orders-actions/AddGlobalDiscount";
import AddSameWordArticle from "./purchase-orders-actions/AddSameWordArticles";
import SelectArticle from "./purchase-orders-actions/SelectArticle";
import WarehousesTransfers from "../../Global/components/WarehousesTransfers";
import { EventBus } from "@/event-bus";
import {
  round,
  toFixedNoRound,
  createRandomString,
  capitalizeFirstLetter,
} from "@/utils/fns";
import SelectMultipleArticles from "@/modules/Purchases/components/purchase-orders-actions/SelectMultipleArticles.vue";
export default {
  name: "ChangePurchaseOrder",
  components: {},
  props: [
    "newPurchaseOrderProp",
    "currentIndexProp",
    "type",
    "typeName",
    "showReception",
    "blockEditGeneralValues",
  ],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      newPurchaseOrder: this.newPurchaseOrderProp,
      currentIndex: this.currentIndexProp,
      downloading: false,
      downloadingPrices: false,
      providerArticles: [],
      provName: "",
      moment: moment,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "changePurchaseOrder1",
            label: "Acciones",
            display: true,
          },
          {
            field: "changePurchaseOrder2",
            label: "Artículo",
            display: true,
          },
          {
            field: "changePurchaseOrder3",
            label: "Clave del artículo",
            display: true,
          },
          {
            field: "changePurchaseOrder4",
            label: "Presentación",
            display: true,
          },
          {
            field: "changePurchaseOrder5",
            label: "IVA",
            display: true,
          },
          {
            field: "changePurchaseOrder6",
            label: "IEPS",
            display: true,
          },
          {
            field: "changePurchaseOrder7",
            label: "Código del proveedor",
            display: true,
          },
          {
            field: "changePurchaseOrder8",
            label: "Costo",
            display: true,
            hideTable: this.showReception ? false : true,
          },
          {
            field: "changePurchaseOrder9",
            label: "Indirectos",
            display: true,
            hideTable: this.showReception ? false : false,
          },
          {
            field: "changePurchaseOrder10",
            label: "Descuentos totales",
            display: true,
            hideTable: this.showReception ? false : true,
          },
          {
            field: "changePurchaseOrder11",
            label: "Cantidad total",
            display: true,
          },
          {
            field: "changePurchaseOrder12",
            label: "Costo Final",
            display: true,
            hideTable: this.showReception ? false : true,
          },
        ],
        "change-purchase-order-list-columns"
      ),
      NUM_INTERNO: "",
      NUM_INTERNO_PROV: "",
      VENTA: undefined,
      FECHA_PRE: undefined,
    };
  },
  async created() {
    this.newPurchaseOrder.creationDate = new Date(
      this.newPurchaseOrder.creationDate
    );

    moment.locale("es");
  },
  mounted() {
    this.newPurchaseOrder.creationDate = new Date(
      this.newPurchaseOrder.creationDate
    );
    this.provName = this.newPurchaseOrderProp.PRV.NOMBRE_PRV;
    this.NUM_INTERNO = this.newPurchaseOrderProp.NUM_INTERNO;
    this.NUM_INTERNO_PROV = this.newPurchaseOrderProp.NUM_INTERNO_PROV;
    this.FECHA_PRE = this.newPurchaseOrderProp.FECHA_PRE;
    this.VENTA = this.newPurchaseOrderProp.VENTA;

    EventBus.$on("searchArticleSelected", (selection) => {
      let foundItem = 0;
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (singleItem.RENGLON === selection.renglon) {
          foundItem = singleItemIndex;
          this.newPurchaseOrder.currentItems[singleItemIndex].article =
            selection.article;
        }
      }
      this.getProviderCurrentPrice(
        this.newPurchaseOrder.currentItems[foundItem],
        this.newPurchaseOrder.PRV
      );
      this.addRow();
    });
    EventBus.$on("addPurchaseOrderDiscount", (discount) => {
      this.addItemDiscount(discount.article, discount.discounts);
    });
    EventBus.$on("addPurchaseOrderCost", (cost) => {
      this.addItemCost(cost.article, cost.costs);
    });
    EventBus.$on("addShippingCost", (cost) => {
      this.newPurchaseOrder.shippingQuantity = Number(cost.CANTIDAD).toFixed(5);
      this.updateTotals(true);
    });
    EventBus.$on("saveGlobalDiscounts", (discount) => {
      this.newPurchaseOrder.DESCS = discount.discounts;
      this.updateTotals(true);
    });
    EventBus.$on("saveGlobalCost", (cost) => {
      this.newPurchaseOrder.COSTOS = cost.costs;
      this.updateTotals(true);
    });
    EventBus.$on("addSameWordProducts", (article) => {
      for (const singleArticle of article.articles) {
        if (!this.checkRepeatedArticle(singleArticle, this.newPurchaseOrder)) {
          this.addRow(singleArticle);
          this.getProviderCurrentPrice(
            this.newPurchaseOrder.currentItems[this.currentIndex - 1],
            this.newPurchaseOrder.PRV
          );
        }
      }
      this.updateTotals(true);
      this.addRow();
    });
    EventBus.$on("searchMultipleArticleSelected", (article) => {
      for (const singleArticle of article.articles) {
        if (!this.checkRepeatedArticle(singleArticle, this.newPurchaseOrder)) {
          this.addRow(singleArticle);
          this.getProviderCurrentPrice(
            this.newPurchaseOrder.currentItems[this.currentIndex - 1],
            this.newPurchaseOrder.PRV
          );
        }
      }
      this.updateTotals(true);
      this.addRow();
    });

    for (let singleProvider of this.providers) {
      if (singleProvider._id === this.newPurchaseOrder.PRV._id) {
        this.newPurchaseOrder.PRV = singleProvider;
      }
    }
    for (let singleWarehouse of this.warehouses) {
      if (singleWarehouse._id === this.newPurchaseOrder.ALMACEN._id) {
        this.newPurchaseOrder.ALMACEN = singleWarehouse;
      }
    }
    this.updateTotals(true);
    if (this.type === "0") {
      this.addRow();
    }
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    capitalizeString(string) {
      return string ? capitalizeFirstLetter(string) : "";
    },
    async saveRequestOrder() {
      try {
        let savePurchaseOrderData = this.transformPurchaseOrder(
          this.newPurchaseOrder
        );
        let response = await this.$store.dispatch(
          "EDITREQUESTORDER",
          savePurchaseOrderData
        );
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente el pedido",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar el pedido",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el nuevo pedido en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    transformPurchaseOrder(purchaseOrder) {
      let transformedPurchaseOrder = {};
      transformedPurchaseOrder.CLAVE_PED = purchaseOrder.CLAVE_PED;
      transformedPurchaseOrder.TIPO = "P";
      transformedPurchaseOrder.OBSERVA_PED = purchaseOrder.OBSERVA_PED;
      transformedPurchaseOrder.FECHA_PED = purchaseOrder.creationDate;
      transformedPurchaseOrder.CLAVE_ALMACEN = purchaseOrder.ALMACEN._id;
      transformedPurchaseOrder.CLAVE_PRV = purchaseOrder.PRV._id;
      transformedPurchaseOrder.TOTAL_PED = purchaseOrder.total;
      transformedPurchaseOrder.SUBTOTAL_PED = purchaseOrder.subtotal;
      transformedPurchaseOrder.IVA_PED = purchaseOrder.iva;
      transformedPurchaseOrder.FLETE_PED = purchaseOrder.shippingQuantity;
      transformedPurchaseOrder.PORC_FLETEP = purchaseOrder.shippingPercentage;
      transformedPurchaseOrder.EXENTO = purchaseOrder.EXENTO;
      transformedPurchaseOrder.IEPS = purchaseOrder.ieps;
      transformedPurchaseOrder.STATUS_PED =
        this.type === 0 ? "P" : purchaseOrder.STATUS_PED;
      transformedPurchaseOrder.COSTOS = purchaseOrder.COSTOS;
      transformedPurchaseOrder.COSTOS_GTO = purchaseOrder.costs;
      transformedPurchaseOrder.DESCS = purchaseOrder.DESCS;
      transformedPurchaseOrder.DESCS_GTO = purchaseOrder.discounts;
      transformedPurchaseOrder.IMPRESO = purchaseOrder.printTimes;
      transformedPurchaseOrder._id = purchaseOrder._id;
      transformedPurchaseOrder.EMAIL_GUA = purchaseOrder.EMAIL_GUA;
      transformedPurchaseOrder.EMAIL_ACT = this.user.email;
      transformedPurchaseOrder.NUM_INTERNO = purchaseOrder.NUM_INTERNO;
      transformedPurchaseOrder.NUM_INTERNO_PROV = this.NUM_INTERNO_PROV;
      transformedPurchaseOrder.FECHA_PRE = this.FECHA_PRE;
      transformedPurchaseOrder.VENTA = this.VENTA;
      transformedPurchaseOrder.TIPO_CREACION = this.creationType;

      if (purchaseOrder._id) transformedPurchaseOrder._id = purchaseOrder._id;
      let items = [];
      for (let singleItem of purchaseOrder.currentItems) {
        if (singleItem.article && singleItem.quantity > 0) {
          const transformedItem = {
            CLAVE_ART: singleItem.article._id,
            RENGLON: singleItem.RENGLON,
            CANTIDAD_PE: singleItem.quantity,
            CANTIDAD_RE: 0,
            CANTIDAD_PR: singleItem.quantity,
            PORC_IVA: this.getIva(singleItem),
            PORC_IEPS: this.getIeps(singleItem),
            IVA_GTO: singleItem.IVA_GTO,
            IEPS_GTO: singleItem.IEPS_GTO,
            TOTAL: singleItem.totalItem,
            DESCTO_PAR: singleItem.discountTotal,
            COSTO_PAR: singleItem.costTotal,
            COSTO_UNI: singleItem.price,
            DESCS: singleItem.discounts,
            COSTOS: singleItem.costs,
            EXENTO: singleItem.EXENTO,
            PRECIO_ORIGINAL: singleItem.PRECIO_ORIGINAL,
            PRESENTACION: singleItem.PRESENTACION,
            CANTIDAD_PRESENTACION: singleItem.CANTIDAD_PRESENTACION,
          };
          if (singleItem._id) transformedItem._id = singleItem._id;
          items.push(transformedItem);
        }
      }
      transformedPurchaseOrder.items = items;
      return transformedPurchaseOrder;
    },
    checkRepeatedArticle(article, purchaseOrder) {
      for (const singleItem of purchaseOrder.currentItems) {
        if (singleItem.article && singleItem.article._id === article._id) {
          return true;
        }
      }
      return false;
    },
    addRow(article = null) {
      if (this.newPurchaseOrder.PRV && this.newPurchaseOrder.ALMACEN) {
        this.newPurchaseOrder.currentItems.push({
          article: article,
          quantity: 1,
          RENGLON: this.currentIndex,
          price: 0,
          finalPrice: 0,
          costTotal: 0,
          discountTotal: 0,
          costs: [],
          discounts: [],
          selectedOption: "empty",
        });
        this.currentIndex++;
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay un proveedor o almacén seleccionado",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    resetProviderPick() {
      this.newPurchaseOrder.currentItems = [];
      this.newPurchaseOrder.currentIndex = 1;
      this.addRow();
      this.updateTotals();
    },
    openAddGlobalCost() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalCost,
        props: {
          allCosts: this.newPurchaseOrder.COSTOS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddGlobalDiscount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalDiscount,
        props: {
          allDiscounts: this.newPurchaseOrder.DESCS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    generateReport(type) {
      if (type === 0) {
        this.downloading = true;
        this.downloadingPrices = true;
        html2pdf(this.$refs.requestOrder, {
          margin: [0, 1, 1, 1],
          filename: `Pedido-${this.lastPurchaseOrderNumber}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: "pt", format: "letter", orientation: "landscape" },
        });
        this.downloadingPrices = false;
        this.downloading = false;
      } else if (type === 1) {
        this.downloading = true;
        html2pdf(this.$refs.requestOrder, {
          margin: [0, 1, 1, 1],
          filename: `Pedido-${this.lastPurchaseOrderNumber}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: "pt", format: "letter", orientation: "landscape" },
        });
        this.downloading = false;
      }
    },
    getTotalWeight() {
      let totalWeight = 0;
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (
          singleArticle &&
          singleArticle.article &&
          singleArticle.article.CANT_PESO
        ) {
          totalWeight +=
            this.newPurchaseOrder.currentItems[singleArticleIndex].quantity *
            singleArticle.article.CANT_PESO;
        }
      }
      return totalWeight;
    },
    getTotalVolumen() {
      let totalWeight = 0;
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (
          singleArticle &&
          singleArticle.article &&
          singleArticle.article.CANT_VOL
        ) {
          totalWeight +=
            this.newPurchaseOrder.currentItems[singleArticleIndex].quantity *
            singleArticle.article.CANT_VOL;
        }
      }
      return totalWeight;
    },
    getProviderCurrentPrice(item, providerInformation) {
      item.price = 0;
      item.PRECIO_ORIGINAL = 0;
      item.costTotal = 0;
      item.discountTotal = 0;
      item.costs = [];
      item.quantity = 1;
      item.discounts = [];
      item.EXENTO = "N";
      item.IVA_GTO = 0;
      item.IEPS_GTO = 0;
      item.PRESENTACION = "UB";
      item.CANTIDAD_PRESENTACION = 1;
      if (item.article) {
        for (const singleProviderPrice of item.article.PRECIOS_PROV) {
          if (singleProviderPrice.CLAVE_PRV === providerInformation._id) {
            item.price = singleProviderPrice.COSTO;
            item.PRECIO_ORIGINAL = singleProviderPrice.COSTO;
            item.costs = singleProviderPrice.COSTOS;
            item.discounts = singleProviderPrice.DESCS;
            break;
          }
        }
      }
      if (item.article && item.article.TIPO_IVA === "EX") {
        item.EXENTO = "S";
      }
      this.updateTotals(true);
      return;
    },
    getRedPrice(item) {
      if (item.article) {
        if (item.article.DESCONTINUADO) {
          return true;
        }

        return item.price >= item.article.COSTO_MIN_VTA;
      }

      return false;
    },
    deleteRow(item) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (singleItem.article && singleItem.article._id === item.article._id) {
          this.newPurchaseOrder.currentItems.splice(singleItemIndex, 1);
        }
      }
      this.updateTotals(true);
    },
    openAddCost(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCost,
        props: {
          isGeneral: true,
          item: item,
          isPurchaseOrder: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddDiscount(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddDiscount,
        props: {
          isGeneral: true,
          item: item,
          isPurchaseOrder: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddShippingCost(requestOrder) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddShippingCost,
        props: {
          shippingQuantity: requestOrder.shippingQuantity,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleOptionChange(item) {
      if (item.selectedOption === "deleteItem") {
        this.deleteRow(item);
      } else if (item.selectedOption === "discount") {
        this.openAddDiscount(item);
      } else if (item.selectedOption === "cost") {
        this.openAddCost(item);
      } else if (item.selectedOption === "restartPrice") {
        item.price = item.PRECIO_ORIGINAL;
        this.updateTotals();
      }

      setTimeout(() => {
        item.selectedOption = "empty";
        item.RENGLON++;
      }, 500);
    },
    addItemDiscount(item, discounts) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (singleItem.article && singleItem.article._id === item._id) {
          this.newPurchaseOrder.currentItems[singleItemIndex].discounts =
            discounts;
          break;
        }
      }

      this.updateTotals(true);
    },
    addItemCost(item, costs) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (singleItem.article && singleItem.article._id === item._id) {
          this.newPurchaseOrder.currentItems[singleItemIndex].costs = costs;
          break;
        }
      }
      this.updateTotals(true);
    },
    getInventoryMaximum(article) {
      for (const singleWarehouse of article.CANT_ALMACENES) {
        if (
          singleWarehouse.CLAVE_ALMACEN === this.newPurchaseOrder.ALMACEN._id
        ) {
          return singleWarehouse.MAXIMO;
        }
      }
      return 0;
    },
    getInventoryWarehouse(article, saleInventory = false) {
      for (const singleWarehouse of article.CANT_ALMACENES) {
        if (
          singleWarehouse.CLAVE_ALMACEN === this.newPurchaseOrder.ALMACEN._id
        ) {
          let totalQuantity = round(Number(singleWarehouse.CANTIDAD), 4);
          if (saleInventory) {
            if (singleWarehouse.CANTIDAD_VENTAS) {
              totalQuantity += round(
                Number(singleWarehouse.CANTIDAD_VENTAS),
                4
              );
            }

            if (singleWarehouse.CANTIDAD_VIRTUAL) {
              totalQuantity += round(
                Number(singleWarehouse.CANTIDAD_VIRTUAL),
                4
              );
            }
          }
          return totalQuantity;
        }
      }
      return 0;
    },
    getProviderCode(article) {
      if (article && article.PRECIOS_PROV) {
        for (const singleProvider of article.PRECIOS_PROV) {
          if (singleProvider.CLAVE_PRV === this.newPurchaseOrder.PRV._id) {
            return singleProvider.CLAVE_ARTPRV;
          }
        }
      }
      return undefined;
    },
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    getIeps(item) {
      if (item.article) {
        if (
          item.article.CANT_IEPS !== undefined &&
          item.article.CANT_IEPS !== null
        ) {
          return item.article.CANT_IEPS;
        } else {
          return 0;
        }
      }
      return 0;
    },
    updateTotals(noUpdate = false) {
      let total = 0;
      let subtotal = 0;
      let subtotalNoExtras = 0;
      let iva = 0;
      let ieps = 0;
      let cost = 0;
      let costsTotal = 0;
      let discount = 0;
      let discountsTotal = 0;
      let excent = 0;

      this.newPurchaseOrder.shippingQuantity = round(
        Number(this.newPurchaseOrder.shippingQuantity).toFixed(5),
        2
      );

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (singleItem.article) {
          this.defaultOpenedDetails.push(singleItem.RENGLON);
          let costItem = 0;
          let discountItem = 0;

          if (!noUpdate) {
            // Check if its a presentation or defualt measure
            if (singleItem.PRESENTACION === "UB") {
              singleItem.CANTIDAD_PRESENTACION = 1;
              singleItem.price = round(singleItem.PRECIO_ORIGINAL, 2);
            } else {
              singleItem.price = round(singleItem.PRECIO_ORIGINAL, 2);
              singleItem.CANTIDAD_PRESENTACION = 1;

              for (const singlePresentation of singleItem.article
                .PRESENTACIONES)
                if (singleItem.PRESENTACION === singlePresentation._id) {
                  singleItem.price = singleItem.PRECIO_ORIGINAL;
                  singleItem.CANTIDAD_PRESENTACION = 1;

                  if (singlePresentation.ESMAYOR === "S") {
                    singleItem.price =
                      singleItem.price * singlePresentation.FACTOR2;

                    singleItem.CANTIDAD_PRESENTACION =
                      1 * singlePresentation.FACTOR2;
                  } else {
                    singleItem.price +=
                      singleItem.price / singlePresentation.FACTOR2;

                    singleItem.CANTIDAD_PRESENTACION =
                      1 / singlePresentation.FACTOR2;
                  }
                }
            }
          }

          let priceQuantity = singleItem.price * singleItem.quantity;

          for (const cost of singleItem.costs) {
            costItem +=
              toFixedNoRound(cost.CANTIDAD, 2) *
              singleItem.CANTIDAD_PRESENTACION;
          }

          costsTotal += singleItem.quantity * costItem;

          for (const discount of singleItem.discounts) {
            discountItem +=
              toFixedNoRound(discount.CANTIDAD, 2) *
              singleItem.CANTIDAD_PRESENTACION;
          }

          discountsTotal += singleItem.quantity * discountItem;

          let subTotalItem =
            priceQuantity +
            singleItem.quantity * costItem -
            singleItem.quantity * discountItem;

          subtotal += subTotalItem;
          subtotalNoExtras += priceQuantity;

          let totalItem = 0;
          totalItem += subTotalItem;

          // Add Iva to the total
          let itemIva = subTotalItem * (this.getIva(singleItem) / 100);
          totalItem += itemIva;
          iva += itemIva;

          // Add Ieps to the total
          let itemIeps = subTotalItem * (this.getIeps(singleItem) / 100);
          totalItem += itemIeps;
          ieps += itemIeps;

          // Add iva excent to excent total
          if (singleItem.article && singleItem.article.TIPO_IVA === "EX") {
            excent += subTotalItem;
          }

          // calculate final cost value
          let finalDiscountItemValue = singleItem.quantity * discountItem;
          let finalCostValue = priceQuantity - finalDiscountItemValue;

          this.newPurchaseOrder.currentItems[singleItemIndex].discountTotal =
            toFixedNoRound(discountItem, 3);
          this.newPurchaseOrder.currentItems[singleItemIndex].costTotal =
            toFixedNoRound(costItem, 3);
          this.newPurchaseOrder.currentItems[singleItemIndex].finalPrice =
            toFixedNoRound(finalCostValue, 3);
          this.newPurchaseOrder.currentItems[singleItemIndex].totalItem =
            toFixedNoRound(totalItem, 3);
          this.newPurchaseOrder.currentItems[singleItemIndex].IVA_GTO =
            toFixedNoRound(itemIva, 3);
          this.newPurchaseOrder.currentItems[singleItemIndex].IEPS_GTO =
            toFixedNoRound(itemIeps, 3);
        }
      }

      let currentSubtotal = subtotal;
      for (let singleDiscount of this.newPurchaseOrder.DESCS) {
        let ivaPercentage = iva / currentSubtotal;
        let iepsPercentage = ieps / currentSubtotal;

        if (singleDiscount.TIPO === "PO") {
          let discountQuantity =
            currentSubtotal * (singleDiscount.PORCENTAJE / 100);
          currentSubtotal -= discountQuantity;
          discount += discountQuantity;
          discountsTotal += discountQuantity;

          iva -= ivaPercentage * discountQuantity;
          ieps -= iepsPercentage * discountQuantity;
        } else if (singleDiscount.TIPO === "CA") {
          discount += Number(singleDiscount.CANTIDAD);
          discountsTotal += Number(singleDiscount.CANTIDAD);

          iva -= ivaPercentage * Number(singleDiscount.CANTIDAD);
          ieps -= iepsPercentage * Number(singleDiscount.CANTIDAD);
        }
      }

      for (let singleCost of this.newPurchaseOrder.COSTOS) {
        let ivaPercentage = iva / currentSubtotal;
        let iepsPercentage = iva / currentSubtotal;

        if (singleCost.TIPO === "PO") {
          let costQuantity = currentSubtotal * (singleCost.PORCENTAJE / 100);
          currentSubtotal += costQuantity;
          cost += costQuantity;
          costsTotal += costQuantity;

          iva += ivaPercentage * costQuantity;
          ieps += iepsPercentage * costQuantity;
        } else if (singleCost.TIPO === "CA") {
          cost += Number(singleCost.CANTIDAD);
          costsTotal += Number(singleCost.CANTIDAD);

          iva += ivaPercentage * Number(singleCost.CANTIDAD);
          ieps += iepsPercentage * Number(singleCost.CANTIDAD);
        }
      }

      total +=
        subtotal -
        discount +
        cost +
        iva +
        ieps +
        this.newPurchaseOrder.shippingQuantity;

      this.newPurchaseOrder.subtotal = toFixedNoRound(subtotalNoExtras, 3);
      this.newPurchaseOrder.total = toFixedNoRound(total, 3);
      this.newPurchaseOrder.discounts = toFixedNoRound(discountsTotal, 3);
      this.newPurchaseOrder.costs = toFixedNoRound(costsTotal, 3);
      this.newPurchaseOrder.iva = toFixedNoRound(iva, 3);
      this.newPurchaseOrder.ieps = toFixedNoRound(ieps, 3);
      this.newPurchaseOrder.EXENTO = toFixedNoRound(excent, 3);
    },
    openAddArticlesSameWord() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSameWordArticle,
        props: {
          providerInformation: this.newPurchaseOrderProp.PRV,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddMultipleArticles() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectMultipleArticles,
        props: {
          currentArticles: this.newPurchaseOrder.currentItems,
          providerInformation: this.newPurchaseOrderProp.PRV,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    getRandomString() {
      return createRandomString(6);
    },
    openSearchArticle(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectArticle,
        props: {
          currentArticles: this.newPurchaseOrder.currentItems,
          providerInformation: this.newPurchaseOrder.PRV,
          renglon: item.RENGLON,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    checkArticleRepeated(article) {
      for (const singleItem of this.newPurchaseOrder.currentItems) {
        if (
          singleItem.article &&
          singleItem.article.CLAVE_ART === article.CLAVE_ART
        ) {
          return false;
        }
      }
      return true;
    },
    autoCompleteProviders(value) {
      if (!this.providers) {
        return [];
      }
      return this.providers.filter((singlProvider) => {
        return (
          singlProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singlProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    openKardex() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: WarehousesTransfers,
        props: {
          type: "1",
          showTitle: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    roundQuantities() {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        this.newPurchaseOrder.currentItems[singleArticleIndex].quantity =
          Math.ceil(singleArticle.quantity);
      }

      this.updateTotals();
      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Redondeo realizado con éxito",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {},
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    roundQuantitiesSpecial() {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        this.newPurchaseOrder.currentItems[singleArticleIndex].quantity =
          Math.ceil(singleArticle.quantity / 5) * 5;
      }

      this.updateTotals();
      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Redondeo realizado con éxito",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {},
        cancelText: "cancelar",
        canCancel: false,
      });
    },
    getItemBackOrder(rowInformation) {
      let currentItemMissingQuantity = 0;

      for (const singlePurchaseOrder of this.purchaseOrders) {
        if (
          singlePurchaseOrder.STATUS_PED !== "CA" &&
          singlePurchaseOrder.STATUS_PED !== "C"
        ) {
          for (const singleItem of singlePurchaseOrder.PEDIDO_DET) {
            if (
              rowInformation.article &&
              singleItem.CLAVE_ART &&
              singleItem.CLAVE_ART._id === rowInformation.article._id &&
              singlePurchaseOrder.CLAVE_ALMACEN._id ===
                this.newPurchaseOrder.ALMACEN._id
            ) {
              if (singleItem.CANTIDAD_PR > 0) {
                currentItemMissingQuantity += singleItem.CANTIDAD_PR;
              }
            }
          }
        }
      }

      return currentItemMissingQuantity;
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      })
        .filter((singleProvider) => {
          return (
            !singleProvider.TIPO_PRV ||
            singleProvider.TIPO_PRV === "PR" ||
            singleProvider.TIPO_PRV === "PA"
          );
        })
        .sort((a, b) => {
          return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
        });
    },
    providerComputed() {
      return this.newPurchaseOrder.PRV;
    },
    checkDisable() {
      if (
        this.selectedWarehouses &&
        this.newPurchaseOrder.PRV &&
        this.selectedWarehouses.length !== 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkDisableItems() {
      for (const singleItem of this.newPurchaseOrder.currentItems) {
        if (singleItem.article) return false;
      }
      return true;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "CO" || singleEmployee.TIPO === "VC";
      });
    },
    user() {
      return this.$store.getters.USER;
    },
    users() {
      return this.$store.getters.USERS;
    },
    purchaseOrders() {
      return this.$store.getters.PURCHASEORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        return purchaseOrderWithProps;
      }).filter((singlePurchaseOrder) => {
        return singlePurchaseOrder.RESTA > 0;
      });
    },
  },
  watch: {
    // Restarts the request order if the provider is restarted
    providerComputed() {
      if (this.newPurchaseOrder.PRV != null) {
        //Update articles to select only articles with selected provider price
        this.providerArticles = [];
        for (const singleArticle of this.articles) {
          let articleWithProps = { ...singleArticle };

          for (const singleProviderPrice of articleWithProps.PRECIOS_PROV) {
            if (singleProviderPrice.CLAVE_PRV === this.providerComputed._id) {
              this.providerArticles.push(articleWithProps);
              break;
            }
          }
        }

        this.providerArticles.sort((a, b) =>
          a.DESCRIPCION > b.DESCRIPCION ? 1 : -1
        );

        for (const [
          singleItemIndex,
          singleItem,
        ] of this.newPurchaseOrder.currentItems.entries()) {
          for (const singleProviderArticle of this.providerArticles) {
            if (
              singleItem.article &&
              singleItem.article._id === singleProviderArticle._id
            ) {
              this.newPurchaseOrder.currentItems[singleItemIndex].article =
                singleProviderArticle;
              break;
            }
          }
        }
      }
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "change-purchase-order-list-columns"
        );
      },
      deep: true,
    },
  },
  filters: {
    currency(value) {
      return value.toFixed(5);
    },
    weight(value) {
      value = value.toString();
      return `${value} Kg`;
    },
    volume(value) {
      value = value.toString();
      return `${value} M³`;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/AddPurchaseOrder.css";
</style>
