var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[(_vm.showTitle)?_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Kardex y movimientos en almacenes ")]):_vm._e(),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-container"}),_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1)]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de Almacenes: "),_c('b',[_vm._v(_vm._s(_vm.warehouses.length))])]),_c('b-table',{ref:"WarehousesTable",attrs:{"pagination-position":"both","data":_vm.warehouses,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedWarehouses,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"desc","checkbox-position":"left","default-sort":"CLAVE_ALMACEN"},on:{"update:checkedRows":function($event){_vm.checkedWarehouses=$event},"update:checked-rows":function($event){_vm.checkedWarehouses=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
          message: ("Información de " + (row.CLAVE_ALMACEN)),
          type: 'is-success',
          position: 'is-bottom',
        }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay almacenes")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),_c('span',[_vm._v(_vm._s(props.row[column.field]))])])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"details-controls-container"},[_c('div',{staticClass:"controls"},[(
                  _vm.articles.length === 0 ||
                  (_vm.warehouses.length === 0 && _vm.type !== '1')
                )?_c('b-tooltip',{attrs:{"label":"Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Ver historial de movimientos en almacén")])],1):_vm._e(),(_vm.warehouses.length && _vm.type === '1')?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openWarehouseMovements(props.row)}}},[_vm._v("Ver historial de movimientos en almacén")]):_vm._e(),(
                  _vm.articles.length === 0 ||
                  (_vm.warehouses.length === 0 && _vm.type !== '2')
                )?_c('b-tooltip',{attrs:{"label":"Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Ver transferencias pendientes")])],1):_vm._e(),(_vm.warehouses.length && _vm.type === '2')?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openPendingTransfers(props.row)}}},[_vm._v("Ver transferencias pendientes")]):_vm._e()],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }