/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      {{ typeName.charAt(0).toUpperCase() + typeName.slice(1) }}
    </h1>

    <div class="global-form-hint-container" v-if="type === 0 && !puchaseView">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Recuerda llenar los
      campos obligatorios antes de poder agregar artículos a la cotización. Las
      cotizaciones no afectan inventarios, cuentas por cobrar ni son facturadas.
    </div>

    <div class="global-form-hint-container" v-if="type === 1 && !puchaseView">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Recuerda llenar los
      campos obligatorios antes de poder agregar artículos a la venta.
    </div>

    <div v-if="!puchaseView">
      <div
        v-if="selectedClientInformation && newSale.CONDICION_P === 'R'"
        class="special-margin"
      >
        <h3 class="form-division">Crédito disponible</h3>

        <div class="control add-global-controls">
          <b-button
            type="is-primary"
            class="add-articles-special-margin"
            :disabled="
              !newSale.CLIENTE || blockEdit || newSale.CONDICION_P !== 'R'
            "
            @click="openSearchClientUnlock(newSale)"
            >Desbloquear cliente</b-button
          >
        </div>

        <b-field
          label-position="inside"
          label="Crédito disponible"
          class="special-margin"
        >
          <MoneyInput
            currency="MXN"
            :digits="5"
            placeholder="Ejemplo: 16"
            :required="false"
            :value="selectedClientInformation.remainingCredit"
            :disabled="true"
          >
          </MoneyInput>
        </b-field>
        <b-progress
          :rounded="false"
          type="is-success"
          size="is-medium"
          :value="selectedClientInformation.remainingCredit"
          :max="selectedClientInformation.LIMITE_CRED"
          show-value
          format="raw"
          :precision="2"
          :keep-trailing-zeroes="true"
          locale="es-MX"
        >
          {{ selectedClientInformation.remainingCredit | money("MXN", 2) }}
        </b-progress>
      </div>

      <div class="control add-global-controls">
        <b-button
          type="is-primary"
          class="add-articles-special-margin"
          :disabled="!newSale.CLIENTE || blockEdit || !newSale.ALMACEN"
          @click="openSearchWarehouseUnlock(newSale)"
          >Desbloquear venta en negativo</b-button
        >
      </div>

      <h3 class="form-division">Información general</h3>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        v-model="newSale.sendMaterial"
        :disabled="
          (saleInformation && type !== 0) ||
          (companyPermissions &&
            companyPermissions.global.sendMaterialDelivery === 'N')
        "
      >
        Marcar material como enviado
      </b-checkbox>

      <b-checkbox
        v-model="newSale.requireInvoice"
        class="permissions-checkbox first-row-checkbox"
        @input="handleRequireInvoice()"
        v-if="
          newSale.CLIENTE &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          userPermissions &&
          userPermissions.accounting.saveInvoice === 'S'
        "
        :disabled="
          !newSale.CLIENTE ||
          (saleInformation && type !== 0) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD') ||
          newSale.requireInvoiceAfterSend ||
          newSale.requireParcialInvoiceAfterSend
        "
      >
        Facturar venta
      </b-checkbox>

      <b-checkbox
        v-model="newSale.requireInvoiceAfterSend"
        class="permissions-checkbox first-row-checkbox"
        @input="handleRequireInvoiceAfterSend()"
        v-if="
          newSale.CLIENTE &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          userPermissions &&
          userPermissions.accounting.saveInvoice === 'S' &&
          companyPermissions &&
          companyPermissions.global.sendMaterialDelivery === 'S'
        "
        :disabled="
          !newSale.CLIENTE ||
          (saleInformation && type !== 0) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD') ||
          newSale.requireInvoice ||
          newSale.requireParcialInvoiceAfterSend
        "
      >
        Facturar venta total despues de primer envio de material
      </b-checkbox>

      <b-checkbox
        v-model="newSale.requireParcialInvoiceAfterSend"
        class="permissions-checkbox first-row-checkbox"
        @input="handleRequireParcialInvoiceAfterSend()"
        v-if="
          newSale.CLIENTE &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          userPermissions &&
          userPermissions.accounting.saveInvoice === 'S' &&
          companyPermissions &&
          companyPermissions.global.sendMaterialDelivery === 'S'
        "
        :disabled="
          !newSale.CLIENTE ||
          (saleInformation && type !== 0) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD') ||
          newSale.requireInvoice ||
          newSale.requireInvoiceAfterSend
        "
      >
        Facturar venta parcial despues cada envio de material
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        v-model="newSale.useAdvancedPayment"
        @input="handleUseAdvancedPayment()"
        v-if="
          newSale.CLIENTE &&
          !newSale.requireInvoice &&
          !newSale.requireInvoiceAfterSend &&
          !newSale.requireParcialInvoiceAfterSend &&
          userPermissions &&
          userPermissions.treasury.useAdvancedPayment === 'S'
        "
        :disabled="
          !newSale.CLIENTE ||
          (saleInformation && type !== 0) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD')
        "
      >
        Usar anticipo
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        v-model="newSale.useAdvancedPayment"
        @input="handleUseAdvancedPayment()"
        v-if="
          newSale.CLIENTE &&
          (newSale.requireInvoice || newSale.requireInvoiceAfterSend) &&
          !newSale.requireParcialInvoiceAfterSend &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          userPermissions &&
          userPermissions.treasury.useAdvancedPayment === 'S' &&
          userPermissions.accounting.saveInvoice === 'S'
        "
        :disabled="
          !newSale.CLIENTE ||
          (saleInformation && type !== 0) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD')
        "
      >
        Usar anticipo (Amortización)
      </b-checkbox>

      <b-checkbox
        class="permissions-checkbox first-row-checkbox"
        v-model="newSale.relateCFDI"
        v-if="
          newSale.CLIENTE &&
          (newSale.requireInvoice || newSale.requireInvoiceAfterSend) &&
          !newSale.requireParcialInvoiceAfterSend &&
          userPermissions &&
          userPermissions.accounting.saveInvoice === 'S' &&
          !newSale.useAdvancedPayment
        "
        :disabled="
          !newSale.CLIENTE ||
          newSale.useAdvancedPayment ||
          (saleInformation && type !== 0) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD')
        "
      >
        Relacionar cfdi
      </b-checkbox>

      <b-checkbox
        v-model="newSale.divideCFDI"
        v-if="
          checkCanDivideCashInvoice() &&
          !newSale.useAdvancedPayment &&
          !newSale.relateCFDI
        "
        :disabled="
          !newSale.CLIENTE ||
          (saleInformation && !blockEdit) ||
          (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
          (saleInformation && saleInformation.STATUS_FAC === 'FD')
        "
      >
        Dividir facturas no mayores a $2000.00
      </b-checkbox>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Razón social extra (Opc)"
        >
          <b-select
            placeholder="Ejemplo: Sin razón social extra"
            icon="domain"
            expanded
            v-model="newSale.RAZON_SOCIAL"
            :disabled="
              saleInformation ||
              (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
              (saleInformation && saleInformation.STATUS_FAC === 'FD')
            "
          >
            <option :value="undefined">Sin razón social extra</option>
            <option
              :value="singleCompanyName._id"
              v-for="singleCompanyName in companyNames"
              :key="singleCompanyName._id"
            >
              {{ singleCompanyName.NOMBRE_FAC }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Vendedor (Opc)"
        >
          <b-select
            placeholder="Ejemplo: Sin vendedor"
            icon="account-tie"
            expanded
            v-model="newSale.VENDEDOR"
            :disabled="
              saleInformation ||
              (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
              (saleInformation && saleInformation.STATUS_FAC === 'FD') ||
              (currentClientInformation &&
                currentClientInformation.CLAVE_VENDEDOR)
            "
          >
            <option :value="undefined">Sin vendedor</option>
            <option
              :value="singleEmployee._id"
              v-for="singleEmployee in employees"
              :key="singleEmployee._id"
            >
              {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
              {{ " " }}
              {{ singleEmployee.MATERNO }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-second required-input"
          label-position="inside"
          label="Cliente (Req)"
        >
          <b-autocomplete
            :data="autoCompleteClients(clientName)"
            field="NOMBRE_EMPRESA"
            v-model="clientName"
            :disabled="
              saleInformation ||
              (saleInformation && saleInformation.STATUS_FAC === 'TI') ||
              (saleInformation && saleInformation.STATUS_FAC === 'FD')
            "
            placeholder="Cliente"
            icon="earth"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                if (option) {
                  newSale.CLIENTE = option._id;
                  handleClientChange();
                }
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron clientes</template>
          </b-autocomplete>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="RFC del cliente (Req)"
        >
          <b-input
            disabled
            placeholder="Ejemplo: XAXX010101000"
            :value="getClientRFC()"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label-position="inside"
          label="Uso del CFDI (Req)"
          expanded
        >
          <b-select
            :disabled="
              saleInformation &&
              saleInformation.STATUS_FAC &&
              saleInformation.STATUS_FAC !== 'SF' &&
              saleInformation.STATUS_FAC !== 'PP'
            "
            placeholder="Ejemplo: Uso del CFDI"
            icon="domain"
            expanded
            v-model="newSale.CLAVE_USO_SAT"
            required
            class="required-input"
          >
            <option value="" disabled>Seleccione un uso del CFDI</option>
            <option
              v-for="singleCfdiUse in cfdiUses"
              :value="singleCfdiUse._id"
              :key="singleCfdiUse._id"
            >
              {{ singleCfdiUse.CVE_USO_SAT }} {{ singleCfdiUse.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label="Régimen fiscal (Req)"
          expanded
          label-position="inside"
        >
          <b-select
            :disabled="
              saleInformation &&
              saleInformation.STATUS_FAC &&
              saleInformation.STATUS_FAC !== 'SF' &&
              saleInformation.STATUS_FAC !== 'PP'
            "
            placeholder="Ejemplo: Régimen fiscal"
            icon="briefcase-outline"
            expanded
            v-model="newSale.CLAVE_REGIMEN"
            required
            class="required-input"
          >
            <option value="" disabled>Seleccione un régimen</option>
            <option
              v-for="singleRegimen in regimenes"
              :value="singleRegimen._id"
              :key="singleRegimen._id"
            >
              {{ singleRegimen.CVE_REG_SAT }} {{ singleRegimen.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four"
          label-position="inside"
          label="Fecha (Req)"
        >
          <b-datepicker
            :disabled="blockEdit"
            required
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha de creación"
            icon="calendar-today"
            v-model="newSale.FECHA"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-four required-input"
          label-position="inside"
          label="Almacen (Req)"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Almacen"
            icon="warehouse"
            v-model="newSale.ALMACEN"
            expanded
            rquired
          >
            <option value="" disabled>Seleccione un almacén</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse._id"
              :key="warehouse.CLAVE_ALMACEN"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>
        <b-field
          v-if="newSale.useAdvancedPayment"
          class="invoice-add-secondary-item-one required-input"
          :label="
            newSale.requireInvoice ||
            (newSale.requireInvoiceAfterSend &&
              !newSale.requireParcialInvoiceAfterSend)
              ? 'Anticipo timbrado (Req)'
              : 'Anticipo sin timbrar (Req)'
          "
          expanded
          label-position="inside"
        >
          <b-select
            class="required-input"
            placeholder="Ejemplo: 100"
            expanded
            icon="credit-card-outline"
            v-model="newSale.CLAVE_ANTICIPO"
            @input="handleAdvancedPaymentSelection()"
            :disabled="type !== 0 && saleInformation"
          >
            <option value="" disabled>Sin seleccionar</option>
            <option
              :value="singleAdvancesPayment._id"
              v-for="singleAdvancesPayment in advancedPayment"
              v-bind:key="singleAdvancesPayment._id"
            >
              {{ singleAdvancesPayment.NUM_FOLIO }}{{ "  "
              }}{{ singleAdvancesPayment.PAGO_PARC | money("MXN", 2) }}
            </option>
          </b-select>
        </b-field>
        <b-field
          v-if="newSale.useAdvancedPayment && currentAdvancedPaymentInformation"
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Saldo restante del anticipo a usar (Req)"
        >
          <MoneyInput
            currency="MXN"
            :digits="4"
            placeholder="Folio"
            icon="cash"
            :value="currentAdvancedPaymentInformation.RESTA"
            disabled
            required
          >
          </MoneyInput>
        </b-field>
        <b-field
          v-if="newSale.useAdvancedPayment"
          class="invoice-add-secondary-item-one"
          label-position="inside"
          label="Cantidad del anticipo a usar (Req)"
        >
          <MoneyInput
            currency="MXN"
            :digits="4"
            placeholder="Folio"
            icon="cash"
            v-model="newSale.CANTIDAD_ANTICIPO"
            :disabled="type !== 0 && saleInformation"
            required
          >
          </MoneyInput>
        </b-field>
        <b-field
          v-if="newSale.relateCFDI"
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="UUID del CFDI a relacionar (Opc)"
        >
          <b-input
            placeholder="Folio"
            icon="pound"
            v-model="newSale.UUID_CFDI"
            :disabled="type !== 0 && saleInformation"
          >
          </b-input>
        </b-field>
        <b-field
          v-if="newSale.relateCFDI"
          class="invoice-add-secondary-item-second"
          label="Tipo de relación (Opc)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Tipo de relación"
            icon="relation-many-to-many"
            expanded
            v-model="newSale.TIPO_R"
          >
            <option value="" disabled>Selecciones un tipo de relación</option>
            <option
              :value="singleRelation.CLAVE"
              v-for="singleRelation in cfdiRelations"
              v-bind:key="singleRelation.CLAVE"
            >
              {{ singleRelation.CLAVE }} {{ singleRelation.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="Orden de compra del cliente (Opc)"
        >
          <b-input
            placeholder="Ejemplo: 123"
            v-model="newSale.OC_CLIENTE"
            icon="numeric"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="Código aleatorio único (Opc)"
        >
          <b-input
            placeholder="Ejemplo: 123"
            v-model="newSale.CODIGO_ENVIO"
            icon="code-brackets"
          >
          </b-input>
        </b-field>
      </div>

      <h3 class="form-division">Información de pago</h3>
      <div class="invoice-add-item special-margin">
        <b-field
          class="required-input"
          :class="
            newSale.CONDICION_P !== 'R'
              ? 'invoice-add-secondary-item-four'
              : 'invoice-add-secondary-item-three'
          "
          label-position="inside"
          label="Condición de pago (Req)"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Condición de pago"
            icon="credit-card-settings-outline"
            v-model="newSale.CONDICION_P"
            expanded
            @input="handlePaymentConditionChange()"
          >
            <option value="" disabled>Seleccione una condición de pago</option>
            <option value="C">Contado</option>
            <option value="R">Crédito</option>
          </b-select>
        </b-field>
        <b-field
          class="required-input"
          :class="
            newSale.CONDICION_P !== 'R'
              ? 'invoice-add-secondary-item-four'
              : 'invoice-add-secondary-item-three'
          "
          label-position="inside"
          label="Método de pago (Req)"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Método de pago"
            icon="numeric"
            v-model="newSale.METODO_P"
            expanded
            @input="handlePaymentMethodChange()"
          >
            <option value="" disabled>Seleccione un método de pago</option>
            <option value="P">Pago en una sola exhibición (PUE)</option>
            <option value="D">Pago en parcialidades o diferido (PPD)</option>
          </b-select>
        </b-field>
        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          class="invoice-add-secondary-item-four"
          label="Forma de pago (Req)"
          expanded
          label-position="inside"
        >
          <b-select
            :disabled="blockEdit"
            placeholder="Ejemplo: Forma de pago"
            icon="credit-card-multiple-outline"
            expanded
            v-model="newSale.FORMA_P"
            required
            class="required-input"
            @input="handlePaymentFormChange()"
          >
            <option value="" disabled>Selecciones una forma de pago</option>
            <option
              :value="singlePaymentType._id"
              v-for="singlePaymentType in paymentTypes"
              v-bind:key="singlePaymentType._id"
            >
              {{ singlePaymentType.DESCRIBE_P }}
            </option>
          </b-select>
        </b-field>
        <b-field
          label-position="inside"
          label="Días de crédito (Req)"
          :class="
            newSale.CONDICION_P !== 'R'
              ? 'invoice-add-secondary-item-four'
              : 'invoice-add-secondary-item-three'
          "
        >
          <b-input
            required
            placeholder="Días de crédito"
            type="number"
            step="any"
            icon="calendar-range"
            v-model="newSale.PERI_PAGO"
            :disabled="newSale.CONDICION_P === 'C' || blockEdit"
          >
          </b-input>
        </b-field>

        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          class="invoice-add-secondary-item-four"
          label="Número de cuenta (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: Martillo azul con mango de madera"
            expanded
            icon="card-account-details-outline"
            v-model="newSale.NO_CUENTA"
          ></b-input>
        </b-field>

        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          class="invoice-add-secondary-item-four"
          label="CLABE interbancaria (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: 000000000000000000"
            expanded
            icon="bank-outline"
            v-model="newSale.NO_CLABE"
          ></b-input>
        </b-field>
        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          label="Número de tarjeta (Opcional)"
          class="invoice-add-secondary-item-four"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: 0000000000000000"
            expanded
            icon="card-text-outline"
            v-model="newSale.NO_TARJETA"
          ></b-input>
        </b-field>
        <b-field
          v-if="newSale.CONDICION_P !== 'R'"
          label="Número de cheque (Opcional)"
          class="invoice-add-secondary-item-four"
          expanded
          label-position="inside"
        >
          <b-input
            :disabled="blockEdit"
            placeholder="Ejemplo: 12398"
            expanded
            icon="card-bulleted-outline"
            v-model="newSale.NO_CHEQUE"
          ></b-input>
        </b-field>
      </div>

      <h3 class="form-division">Información de envio</h3>
      <div class="invoice-add-item">
        <div class="full-width-div">
          <b-checkbox
            class="permissions-checkbox first-row-checkbox special-margin"
            v-model="newSale.clientPickup"
            :disabled="blockEdit"
          >
            El cliente recoge el material
          </b-checkbox>
        </div>
        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="Fecha de recepción o recolección (Opc)"
        >
          <b-datetimepicker
            :disabled="blockEdit"
            :datetime-formatter="
              (date) => moment(date).format('DD/MM/YYYY HH:mm')
            "
            :datepicker="datepicker"
            v-model="newSale.FECHA_ENVIO"
            placeholder="Ejemplo 20/10/2018"
            trap-focus
            icon="calendar-today"
            horizontal-time-picker
          >
          </b-datetimepicker>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-second"
          label="Vehiculo (Opc)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Camioneta"
            icon="truck"
            expanded
            v-model="newSale.CLAVE_VEHICULO"
            :disabled="blockEdit"
          >
            <option :value="null">Seleccione un vehiculo</option>
            <option
              v-for="singleVehicule in vehicules"
              :value="singleVehicule._id"
              :key="singleVehicule._id"
            >
              {{ singleVehicule.CVE_VEHI }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <section v-if="!checkDisable" class="special-margin">
      <div class="invoice-box">
        <div class="invoice-upper-part">
          <div class="invoice-logo"></div>
          <div class="invoice-information">
            Fecha de creación: {{ creationDateFomatted }}
          </div>
        </div>

        <div class="invoice-company-info-part">
          <div class="invoice-company" v-if="newSale.CLIENTE">
            <p v-if="newSale.CLIENTE && newSale.CLIENTE.NOMBRE_EMPRESA">
              {{ newSale.CLIENTE.NOMBRE_EMPRESA }}<br />
            </p>
            <p v-if="newSale.CLIENTE && newSale.CLIENTE.DOMICIL_CLI">
              {{ newSale.CLIENTE.DOMICIL_CLI }}
            </p>
            <p v-if="newSale.CLIENTE && newSale.CLIENTE.CLAVE_ESTADO">
              {{ newSale.CLIENTE.CLAVE_ESTADO }},{{ newSale.CLIENTE.CP_PRV }}
              <br />
            </p>
          </div>
          <div class="invoice-company-person"></div>
        </div>

        <div class="invoice-payment-info-part">
          <div class="invoice-payment-type-left">
            <strong>Observaciones impresas</strong>
          </div>
          <div class="invoice-payment-type-right">
            <strong>Observaciones internas</strong>
          </div>
        </div>

        <div class="invoice-payment-type-info">
          <div class="invoice-payment-type-info-left invoice-observation">
            <b-input type="textarea" v-model="newSale.OBSERVA_IMP"></b-input>
          </div>
          <div class="invoice-payment-type-info-right invoice-observation">
            <b-input type="textarea" v-model="newSale.OBSERVA"></b-input>
          </div>
        </div>

        <h3 class="purchase-order-table-title">Artículos en {{ typeName }}</h3>
        <div class="table-header-left">
          Peso total: <b>{{ getTotalWeight() | weight }}</b>
        </div>

        <div class="control add-global-controls">
          <b-button
            type="is-primary"
            @click="openAddMultipleArticles()"
            :disabled="saleInformation"
            >Seleccionar multiples artículos</b-button
          >
        </div>

        <div
          class="global-lists-search no-margin special-margin"
          v-if="!puchaseView"
        >
          <div class="global-lists-filters-container"></div>
          <div class="global-lists-filters-columns no-margin">
            <b-checkbox
              v-for="singleColumn in columns"
              v-bind:key="singleColumn.label"
              v-model="singleColumn.display"
            >
              {{ singleColumn.label }}
            </b-checkbox>
          </div>
        </div>

        <b-table
          pagination-position="both"
          :data="newSale.currentItems"
          :striped="true"
          :bordered="true"
          :hoverable="true"
          ref="purchaseOrder"
          custom-row-key="RENGLON"
          paginated
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="asc"
          class="purchase-order-table"
          scrollable
          detailed
          detail-key="RENGLON"
        >
          <template #empty>
            <div class="has-text-centered">No hay artículos</div>
          </template>

          <template slot-scope="props">
            <b-table-column
              label="Acciones"
              v-if="!blockEdit"
              :visible="columns[0].display"
            >
              <b-field class="invoice-option-select">
                <b-select
                  placeholder="Opciones"
                  v-model="props.row.selectedOption"
                  @input="handleOptionChange(props.row)"
                  v-if="props.row.article"
                >
                  <option value="">Selecciona una opción</option>
                  <option value="deleteItem">Borrar artículo</option>
                  <option value="discount">Editar descuentos</option>
                  <option value="cost">Editar indirectos</option>
                </b-select>
              </b-field>
            </b-table-column>

            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
            <b-table-column
              field="article.DESCRIPCION"
              label="Artículo"
              :visible="columns[1].display"
              :width="500"
            >
              <span v-if="props.row.article">{{
                props.row.article.DESCRIPCION
              }}</span>

              <div class="invoice-article-div" v-if="!props.row.article">
                <b-button
                  icon-right="magnify"
                  size="is-small"
                  type="is-primary"
                  class="search-article"
                  @click="openSearchArticle(props.row, props.row.RENGLON)"
                >
                </b-button>
              </div>
            </b-table-column>
            <!-- eslint-enable -->

            <b-table-column
              label="Clave de artículo"
              field="article.CLAVE_ART"
              sortable
              :visible="columns[2].display"
            >
              <span>{{
                props.row.article ? props.row.article.CLAVE_ART : ""
              }}</span>
            </b-table-column>

            <b-table-column
              label="Lista de precios"
              field="priceList"
              sortable
              :visible="columns[3].display && !puchaseView"
            >
              <b-field class="invoice-option-select">
                <b-select
                  :disabled="blockEdit"
                  placeholder="Ejemplo: General"
                  expanded
                  v-model="props.row.priceList"
                  @input="(value) => changeArticlePriceList(props.row, value)"
                  v-if="props.row.article"
                >
                  <option value="" disabled>Lista de precios</option>
                  <option
                    v-for="singlePriceList in priceLists"
                    :value="singlePriceList._id"
                    :key="singlePriceList._id"
                  >
                    {{ singlePriceList.CLAVE_LISTAP }}
                  </option>
                </b-select>
              </b-field>
            </b-table-column>

            <b-table-column
              label="Presentación"
              field="PRESENTACIONES"
              sortable
              :visible="columns[4].display"
            >
              <b-field class="invoice-option-select">
                <b-select
                  :disabled="blockEdit"
                  placeholder="Ejemplo: PIEZA"
                  expanded
                  v-model="props.row.presentation"
                  @input="
                    (value) => {
                      if (value !== 'UB') {
                        getArticleCurrentPricePresentation(
                          props.row,
                          currentClientInformation,
                          value
                        );
                      } else {
                        getArticleCurrentPrice(
                          props.row,
                          currentClientInformation,
                          true
                        );
                      }
                    }
                  "
                  v-if="props.row.article"
                >
                  <option value="" disabled>Presentaciones</option>
                  <option
                    value="UB"
                    v-if="!props.row.article.UNIDAD_EMP"
                    :disabled="
                      !checkArticleRepeatedPresentation(props.row.article, 'UB')
                    "
                  >
                    Unidad de medida base
                  </option>
                  <option
                    value="UB"
                    v-if="props.row.article.UNIDAD_EMP"
                    :disabled="
                      !checkArticleRepeatedPresentation(props.row.article, 'UB')
                    "
                  >
                    {{
                      capitalizeString(props.row.article.UNIDAD_EMP.DESCRIP_MED)
                    }}
                  </option>
                  <option
                    v-for="singlePresentation in props.row.article
                      .PRESENTACIONES"
                    :value="singlePresentation._id"
                    :key="singlePresentation._id"
                    :disabled="
                      !checkArticleRepeatedPresentation(
                        props.row.article,
                        singlePresentation._id
                      )
                    "
                  >
                    {{ singlePresentation.NOMBRE }}
                  </option>
                </b-select>
              </b-field>
            </b-table-column>

            <b-table-column
              label="Cantidad"
              field="quantity"
              sortable
              :visible="columns[5].display"
            >
              <b-input
                :disabled="blockEdit"
                placeholder="Ejemplo: 0"
                expanded
                v-model="props.row.quantity"
                @input="updateTotals()"
                v-if="props.row.article"
                type="number"
                step="any"
              ></b-input>
            </b-table-column>

            <b-table-column
              label="Precio"
              :visible="columns[6].display && !puchaseView"
            >
              <b-input
                class="special-width-input"
                placeholder="Ejemplo: 100"
                expanded
                v-model="props.row.price"
                @input="updateTotals(true)"
                :disabled="blockEdit"
              ></b-input>
            </b-table-column>

            <b-table-column
              label="Descuentos"
              field="discountTotal"
              sortable
              :visible="columns[7].display && !puchaseView"
            >
              <span v-if="props.row.article">{{
                props.row.discountTotal | money("MXN", 5)
              }}</span>
            </b-table-column>

            <b-table-column
              label="Indirectos"
              field="costTotal"
              sortable
              :visible="columns[8].display && !puchaseView"
            >
              <span v-if="props.row.article">{{
                props.row.costTotal | money("MXN", 5)
              }}</span>
            </b-table-column>

            <b-table-column
              label="Sub total"
              field="subTotal"
              :v-bind="props.row.article"
              sortable
              :visible="columns[9].display && !puchaseView"
            >
              <span v-if="props.row.article">{{
                props.row.subTotal | money("MXN", 2)
              }}</span>
            </b-table-column>

            <b-table-column
              label="IVA"
              :visible="columns[10].display && !puchaseView"
            >
              {{ getIva(props.row) + "%" }}
            </b-table-column>

            <b-table-column
              label="IEPS"
              :visible="columns[11].display && !puchaseView"
            >
              {{ getIeps(props.row) + "%" }}
            </b-table-column>

            <b-table-column
              label="Precio Final"
              field="finalPrice"
              :v-bind="props.row.article"
              sortable
              :visible="columns[12].display && !puchaseView"
            >
              <span v-if="props.row.article">{{
                props.row.finalPrice | money("MXN", 2)
              }}</span>
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="props">
            <article class="media">
              <div class="media-content">
                <div class="content">
                  <div class="title-expandable-item">
                    <!-- eslint-disable vue/valid-v-for -->
                    <div class="title-expandable-item">
                      <strong
                        >Detalles para almacén
                        <span class="global-form-hint-color">
                          {{ newSale.ALMACEN.CLAVE_ALMACEN }}
                        </span></strong
                      >
                    </div>
                    <div class="purchase-order-detail">
                      <b-field
                        v-if="props.row.article"
                        label="Cantidad de piezas pedidas"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          :disabled="blockEdit"
                          placeholder="Ejemplo: 0"
                          expanded
                          v-model="props.row.quantity"
                          @input="updateTotals()"
                          icon="pencil-plus-outline"
                          type="number"
                          step="any"
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Inventario en almacén"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="getInventoryWarehouse(props.row.article)"
                          icon="warehouse"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Piezas por surtir"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          value="0"
                          icon="numeric"
                          v-model="props.row.quantityMissing"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Piezas surtidas"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          value="0"
                          icon="numeric"
                          v-model="props.row.quantitySended"
                          disabled
                        ></b-input>
                      </b-field>
                      <b-field
                        v-if="props.row.article"
                        label="Peso del artículo"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="props.row.article.CANT_PESO | weight"
                          icon="arrow-up"
                          disabled
                          v-if="props.row.article.CANT_PESO"
                        ></b-input>
                        <b-input
                          placeholder="Ejemplo: 0"
                          expanded
                          :value="0 | weight"
                          icon="arrow-up"
                          disabled
                          v-if="!props.row.article.CANT_PESO"
                        ></b-input>
                      </b-field>
                    </div>

                    <!-- eslint-enable -->
                  </div>
                </div>
              </div>
            </article>
          </template>
        </b-table>

        <div v-if="!puchaseView">
          <div class="invoice-payment-total-info-costs">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Subtotal:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.subtotal | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Indirectos:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.costs | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Descuentos:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.discounts | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <!--
          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Flete:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.shippingQuantity | money("MXN", 2)
              }}</strong>
            </div>
          </div>
          -->

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>IVA:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.iva | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info-discounts">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>IEPS:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.ieps | money("MXN", 2)
              }}</strong>
            </div>
          </div>

          <div class="invoice-payment-total-info">
            <div class="invoice-payment-total-left"></div>
            <div class="invoice-payment-total-rigth">
              <strong>Total:</strong>
              <strong class="invoice-payment-total-money">{{
                this.newSale.total | money("MXN", 2)
              }}</strong>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="add-global-controls"
      v-if="saleInformation && !blockEdit && !puchaseView"
    >
      <b-button
        type="is-success"
        @click="saveNewSale(1)"
        :disabled="checkDisableItems"
        >Editar cotización</b-button
      >
      <b-button
        type="is-success"
        @click="
          startConfirmationEvent(
            'success',
            function () {
              confirmQuote(newSale);
            },
            'confirmar cotización'
          )
        "
        >Confirmar cotización (Crear venta)</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div
      class="add-global-controls"
      v-if="saleInformation && blockEdit && !puchaseView"
    >
      <b-button
        v-if="
          (!saleInformation.STATUS_FAC ||
            saleInformation.STATUS_FAC === 'SF' ||
            saleInformation.STATUS_FAC === 'PP') &&
          checkIfCanInvoice(newSale.CLIENTE) &&
          saleInformation.STATUS !== 'CA'
        "
        type="is-success"
        @click="
          startConfirmationEvent(
            'success',
            function () {
              saveInvoice();
            },
            'Facturar venta'
          )
        "
        >Facturar venta</b-button
      >

      <b-tooltip
        v-if="
          (!saleInformation.STATUS_FAC ||
            saleInformation.STATUS_FAC === 'SF' ||
            saleInformation.STATUS_FAC === 'PP') &&
          !checkIfCanInvoice(newSale.CLIENTE)
        "
        position="is-right"
        label="Primero debes llenar todos los datos de facturación requeridos de tu compañía en la sección de 'configuración' y asegurarte que el cliente tenga RFC y código postal"
        multilined
      >
        <b-button type="is-success" disabled>Facturar venta</b-button>
      </b-tooltip>

      <b-button
        v-if="saleInformation"
        type="is-success"
        @click="saveNewSale(1, true)"
        >Editar venta</b-button
      >

      <b-button
        type="is-danger"
        @click="closeModal()"
        v-bind:class="{
          'cancel-button': saleInformation,
        }"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="!saleInformation && !puchaseView">
      <b-button
        type="is-success"
        @click="saveNewSale(0)"
        :disabled="checkDisableItems || saleDisabled"
        >Crear {{ typeName }}</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div class="add-global-controls" v-if="puchaseView">
      <b-button type="is-success" @click="selectSale()"
        >Seleccionar venta</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import AddShippingCost from "../../Purchases/components/purchase-orders-actions/AddShippingCost";
import AddDiscount from "../../Purchases/components/purchase-orders-actions/AddDiscount";
import AddCost from "../../Purchases/components/purchase-orders-actions/AddCost.vue";
import AddGlobalCost from "../../Purchases/components/purchase-orders-actions/AddGlobalCost";
import AddGlobalDiscount from "../../Purchases/components/purchase-orders-actions/AddGlobalDiscount";
import SearchClientUnlock from "./SearchClientUnlock";
import SearchWarehouseUnlock from "./SearchWarehouseUnlock";
import SelectArticle from "./SelectArticle";
import { EventBus } from "@/event-bus";
import {
  round,
  toFixedNoRound,
  capitalizeFirstLetter,
  createRandomStringCamps,
} from "@/utils/fns";
import {
  openConfirmationModalSucces,
  fillObjectForm,
} from "@/utils/component-fns";
import CreditNoteRelations from "../../../data/credit-note-relations";
import { paymentTypeEfectivo } from "@/utils/env-variables-fns";
import { paymentTypePorDefinir } from "@/utils/env-variables-fns";
import SelectMultipleArticlesSales from "@/modules/Sales/components/SelectMultipleArticlesSales.vue";

export default {
  name: "AddSaleModal",
  components: {},
  props: [
    "currentIndexProp",
    "type",
    "typeName",
    "saleInformation",
    "blockEdit",
    "puchaseView",
    "purchaseSale",
  ],
  data() {
    return {
      clientName: "",
      efectivoPaymentId: paymentTypeEfectivo,
      porDefinirPaymentId: paymentTypePorDefinir,
      currentClientInformation: null,
      currentAdvancedPaymentInformation: null,
      currentPage: 1,
      perPage: 10,
      currentIndex: this.currentIndexProp ? this.currentIndexProp : 1,
      datepicker: {
        "month-names": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        "day-names": ["D", "L", "M", "M", "J", "V", "S"],
        "show-week-number": true,
        locale: "es-MX",
      },
      saleDisabled: false,
      newSale: {
        CLIENTE: "",
        ALMACEN: "",
        COSTOS: [],
        DESCS: [],
        OBSERVA: "",
        OBSERVA_IMP: "",
        currentItems: [
          {
            article: null,
            presentation: "",
            quantity: 1,
            RENGLON: this.currentIndexProp ? this.currentIndexProp : 1,
            subTotal: 0,
            price: 0,
            finalPrice: 0,
            costTotal: 0,
            discountTotal: 0,
            costs: [],
            discounts: [],
            selectedOption: "",
            priceList: "",
          },
        ],
        FECHA: new Date(),
        FECHA_ENVIO: undefined,
        subtotal: 0,
        total: 0,
        discounts: 0,
        costs: 0,
        iva: 0,
        ieps: 0,
        shippingQuantity: 0,
        shippingPercentage: 0,
        printTimes: 0,
        status: "Sin verificar",
        perPage: 10,
        currentPage: 1,
        TIPO: this.type === 0 ? "C" : "V",
        POS: "N",
        CONDICION_P: "C",
        METODO_P: "P",
        PERI_PAGO: 0,
        CLAVE_USO_SAT: "",
        CLAVE_REGIMEN: "",
        FORMA_P: paymentTypeEfectivo,
        NO_CUENTA: "",
        NO_CLABE: "",
        NO_TARJETA: "",
        NO_CHEQUE: "",
        UUID_CFDI: "",
        TIPO_R: "",
        CLAVE_ANTICIPO: undefined,
        CANTIDAD_ANTICIPO: 0,
        DESBLOQUEO_CLIENTE: undefined,
        DESBLOQUEO_ALMACEN: undefined,
        requireInvoice: false,
        requireInvoiceAfterSend: false,
        requireParcialInvoiceAfterSend: false,
        relateCFDI: false,
        divideCFDI: false,
        VENDEDOR: undefined,
        RAZON_SOCIAL: undefined,
        newCreditNote: true,
        sendMaterial: false,
        clientPickup: false,
        CLAVE_VEHICULO: undefined,
        useAdvancedPayment: false,
        OC_CLIENTE: "",
        CODIGO_ENVIO: "",
      },
      unlockClientInformation: {},
      downloading: false,
      downloadingPrices: false,
      moment: moment,
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "addSalesModal1",
            label: "Acciones",
            display: true,
          },
          {
            field: "addSalesModal2",
            label: "Artículo",
            display: true,
          },
          {
            field: "addSalesModal3",
            label: "Clave de artículo",
            display: true,
          },
          {
            field: "addSalesModal4",
            label: "Lista de precios",
            display: true,
          },
          {
            field: "addSalesModal5",
            label: "Presentación",
            display: true,
          },
          {
            field: "addSalesModal6",
            label: "Cantidad",
            display: true,
          },
          {
            field: "addSalesModal7",
            label: "Precio",
            display: true,
          },
          {
            field: "addSalesModal8",
            label: "Descuentos",
            display: false,
          },
          {
            field: "addSalesModal9",
            label: "Indirectos",
            display: false,
          },
          {
            field: "addSalesModal10",
            label: "Sub total",
            display: true,
          },
          {
            field: "addSalesModal11",
            label: "IVA",
            display: false,
          },
          {
            field: "addSalesModal12",
            label: "IEPS",
            display: false,
          },
          {
            field: "addSalesModal13",
            label: "Precio Final",
            display: true,
          },
        ],
        "add-sales-modal-list-columns"
      ),
      cfdiRelations: CreditNoteRelations,
      selectedCLientCreditInformation: {
        creditLitmit: 0,
        creditUsed: 0,
        remainingBalance: 0,
      },
      discountErrorMesage: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  async mounted() {
    // Fill the article information in form fields
    if (this.saleInformation) {
      // Fill the form with received information
      fillObjectForm(this.saleInformation, this.newSale);

      if (this.saleInformation.FECHA) {
        this.newSale.FECHA = new Date(this.saleInformation.FECHA);
      }

      if (this.saleInformation.FECHA_ENVIO) {
        this.newSale.FECHA_ENVIO = new Date(this.saleInformation.FECHA_ENVIO);
      }

      if (this.saleInformation.clientPickup) {
        this.newSale.clientPickup = this.saleInformation.clientPickup;
      }

      if (this.saleInformation._id) {
        this.newSale._id = this.saleInformation._id;
      }

      if (this.saleInformation.DESBLOQUEO_CLIENTE) {
        this.newSale.DESBLOQUEO_CLIENTE =
          this.saleInformation.DESBLOQUEO_CLIENTE;
      }

      if (this.saleInformation.DESBLOQUEO_ALMACEN) {
        this.newSale.DESBLOQUEO_ALMACEN =
          this.saleInformation.DESBLOQUEO_ALMACEN;
      }

      if (this.saleInformation.CLAVE_VEHICULO) {
        this.newSale.CLAVE_VEHICULO = this.saleInformation.CLAVE_VEHICULO;
      }

      // Fill in client information
      for (const singleClient of this.clients) {
        if (singleClient._id === this.saleInformation.CLIENTE) {
          this.currentClientInformation = singleClient;
          this.clientName = singleClient.NOMBRE_EMPRESA;

          if (singleClient.CLAVE_AGRUPACION_V2) {
            let creditResponse = await this.$store.dispatch(
              "GETCLIENTV2CREDITLIMIT",
              {
                groupId: singleClient.CLAVE_AGRUPACION_V2,
              }
            );

            if (creditResponse.creditLitmit) {
              this.selectedCLientCreditInformation = { ...creditResponse };
            }
          }

          break;
        }
      }

      if (this.saleInformation.CLAVE_ANTICIPO) {
        this.newSale.CLAVE_ANTICIPO = this.saleInformation.CLAVE_ANTICIPO;
      }

      // Add new row if it's a quote
      if (this.type === 0) {
        if (this.saleInformation.currentItems.length > 0) {
          this.currentIndex =
            this.saleInformation.currentItems[
              this.saleInformation.currentItems.length - 1
            ].RENGLON;
        }
        this.addRow();

        // Set advanced payment information if it's a quote
        for (const singlePayment of this.advancedPayment) {
          if (singlePayment._id === this.newSale.CLAVE_ANTICIPO) {
            this.currentAdvancedPaymentInformation = singlePayment;
          }
        }
      }

      this.newSale.VENDEDOR = this.saleInformation.VENDEDOR;
      this.newSale.RAZON_SOCIAL = this.saleInformation.RAZON_SOCIAL;

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.article) {
          const defaultListPrices = this.getArticleDefaultListPrices(
            singleItem.article
          );
          this.newSale.currentItems[singleItemIndex].article.defaultListPrices =
            defaultListPrices;
        }
      }

      this.updateTotals();

      this.handleAdvancedPaymentSelection();
    }

    if (!this.saleInformation) {
      for (const singleUser of this.users) {
        if (singleUser.email === this.user.email) {
          for (const singleEmployee of this.employees) {
            if (singleUser._id === singleEmployee.USER) {
              this.newSale.VENDEDOR = singleEmployee._id;
            }
          }
        }
      }

      if (this.companyInformation.ENVIAR_MATERIAL_VENTA) {
        this.newSale.sendMaterial = true;
      }

      if (this.companyInformation.AUTOCOMPLETAR_CODIGO_ALEATORIO) {
        this.newSale.CODIGO_ENVIO = this.getRandomString(3);
      }

      if (
        this.companyPermissions &&
        this.companyPermissions.global.sendMaterialDelivery === "N"
      ) {
        this.newSale.sendMaterial = true;
      }
    }

    EventBus.$on("searchArticleSelectedSale", (selection) => {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.RENGLON === selection.RENGLON) {
          this.newSale.currentItems[singleItemIndex].article =
            selection.article;
          this.getArticleCurrentPrice(
            this.newSale.currentItems[singleItemIndex],
            this.currentClientInformation
          );
          this.addRow();
        }
      }
    });
    EventBus.$on("addSaleDiscount", (discount) => {
      this.addItemDiscount(
        discount.article,
        discount.discounts,
        discount.extraInfo
      );
    });
    EventBus.$on("addSaleCost", (cost) => {
      this.addItemCost(cost.article, cost.costs, cost.extraInfo);
    });
    EventBus.$on("addShippingCost", (cost) => {
      this.newSale.shippingQuantity = Number(cost.CANTIDAD).toFixed(5);
      this.updateTotals();
    });
    EventBus.$on("saveGlobalDiscounts", (discount) => {
      this.newSale.DESCS = discount.discounts;
      this.updateTotals();
    });
    EventBus.$on("saveGlobalCost", (cost) => {
      this.newSale.COSTOS = cost.costs;
      this.updateTotals();
    });
    EventBus.$on("clientUnlockFound", (unlockInformation) => {
      this.newSale.DESBLOQUEO_CLIENTE = unlockInformation.clientUnlock._id;
      for (const singleClient of this.clients) {
        if (singleClient._id === this.selectedClientInformation._id) {
          this.unlockClientInformation = unlockInformation.clientUnlock;
        }
      }
    });
    EventBus.$on("warehouseUnlockFound", (unlockInformation) => {
      this.newSale.DESBLOQUEO_ALMACEN = unlockInformation.warehouseUnlock._id;
    });
    EventBus.$on("searchMultipleArticleSelected", (article) => {
      for (const singleArticle of article.articles) {
        const currentItemsLength = this.newSale.currentItems.length - 1;

        this.newSale.currentItems[currentItemsLength].article = singleArticle;
        this.getArticleCurrentPrice(
          this.newSale.currentItems[currentItemsLength],
          this.currentClientInformation
        );

        this.addRow();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    capitalizeString(string) {
      return capitalizeFirstLetter(string);
    },
    /**
     *
     * @desc saves or updates a sale depending on type
     */
    async saveNewSale(saveType, showModal = true, showArticlesWarning = true) {
      try {
        this.saleDisabled = true;
        if (!this.checkClientCanPurchase && saveType === 0) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la ${this.typeName} revise la línea de crédito disponible`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.saleDisabled = false;
          return;
        }
        if (!this.checkSale(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la ${this.typeName} revise los campos`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.saleDisabled = false;
          return;
        }
        if (!this.checkSaleValidPrices(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la ${this.typeName} revise que no se tengan artículos con precio final 0`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.saleDisabled = false;
          return;
        }
        if (!this.checkValidDiscounts(this.newSale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la ${this.typeName}, el total de descuentos de los artículos no puede ser mayor al costo mínimo de venta: ${this.discountErrorMesage}`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.saleDisabled = false;
          return;
        }
        if (
          !this.checkValidAdvancedPayment(this.newSale) &&
          !this.saleInformation
        ) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la ${this.typeName} revise que el saldo restante del anticipo no sea mayor al total de la venta y que la cantidad del anticipo a usar no sea mayor al total de la venta`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          this.saleDisabled = false;
          return;
        }
        if (
          !this.checkArticlesQuantityWarning() &&
          showArticlesWarning &&
          this.newSale.sendMaterial &&
          saveType === 0
        ) {
          this.$buefy.dialog.confirm({
            title: "Confirmar creación de venta",
            message: `No se cuenta con inventario suficiente en uno o más artículos y se marcó el crear un nuevo envio de material de manera automática, ¿Estas seguro que deseas confirmar la creación de la venta?`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: true,
            onConfirm: () => {
              this.saveNewSale(saveType, showModal, false);
            },
          });
          this.saleDisabled = false;
          return;
        }

        let saveSaleData = this.transformSale(this.newSale);

        if (this.type === 0) {
          saveSaleData.FECHA = new Date();
        }

        let response = await this.$store.dispatch(
          saveType === 0
            ? this.type === 0
              ? "SAVEQUOTE"
              : "SAVESALE"
            : this.type === 0
            ? "EDITQUOTE"
            : "EDITSALE",
          saveSaleData
        );
        if (response === "Success") {
          if (showModal) {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: `Se guardó exitosamente la ${this.typeName}`,
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          }

          return "Success";
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la ${
              this.typeName
            }, si es un error de facturación puede intentarlo de nuevo en el detalle de la venta: ${JSON.stringify(
              response
            )}`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
        this.saleDisabled = false;
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la ${
            this.typeName
          } en la base de datos, si es un error de facturación puede intentarlo de nuevo en el detalle de la venta: ${JSON.stringify(
            error
          )}`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.saleDisabled = false;
      }
    },
    /**
     *
     * @desc creates a new invoice of a sale
     */
    async saveInvoice() {
      try {
        let response = await this.$store.dispatch("SAVEINVOICE", {
          saleId: this.newSale._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se generó la factura exitosamente`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al generar la factura:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al generar la factura en la base de datos: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkSale(sale) {
      if (!sale.CLIENTE) return false;
      if (!sale.ALMACEN) return false;
      if (sale.PERI_PAGO === "") return false;
      if (!sale.CONDICION_P) return false;
      if (!sale.CLAVE_USO_SAT) return false;
      if (!sale.CLAVE_REGIMEN) return false;
      if (!sale.METODO_P) return false;
      if (sale.currentItems.length === 0) return false;
      return true;
    },
    checkSaleValidPrices(sale) {
      for (const singleItem of sale.currentItems) {
        if (singleItem.article && singleItem.finalPrice === 0) {
          return false;
        }
      }
      return true;
    },
    checkValidAdvancedPayment(sale) {
      if (!sale.useAdvancedPayment) {
        return true;
      }

      // Autocomplete advanced payment quantity if needed
      if (
        this.newSale.CANTIDAD_ANTICIPO > sale.total ||
        this.newSale.CANTIDAD_ANTICIPO === 0
      ) {
        this.newSale.CANTIDAD_ANTICIPO = sale.total;
      }

      // If advanced payment has no remaining balane
      if (
        this.newSale.CANTIDAD_ANTICIPO >
        this.currentAdvancedPaymentInformation.RESTA + 1
      ) {
        return false;
      }

      return true;
    },
    checkValidDiscounts(sale) {
      this.discountErrorMesage = "";
      let notValidItems = [];

      if (this.type === 1 && this.saleInformation) {
        return true;
      }

      for (const singleItem of sale.currentItems) {
        if (
          singleItem.article &&
          singleItem.presentation === "UB" &&
          singleItem.article.defaultListPrices
        ) {
          const orderedPricesList = singleItem.article.defaultListPrices.sort(
            (a, b) => Number(a.calculatedPrice) - Number(b.calculatedPrice)
          );

          if (
            singleItem.price - singleItem.discountTotal + singleItem.costTotal <
            Number(orderedPricesList[0].calculatedPrice) - 1
          ) {
            notValidItems.push(singleItem);
          }
        }

        if (singleItem.article && singleItem.presentation !== "UB") {
          for (const singlePresentation of singleItem.article.PRESENTACIONES) {
            if (singlePresentation._id === singleItem.presentation) {
              if (singlePresentation.defaultListPrices) {
                const orderedPricesList =
                  singlePresentation.defaultListPrices.sort(
                    (a, b) =>
                      Number(a.calculatedPrice) - Number(b.calculatedPrice)
                  );
                if (
                  singleItem.price -
                    singleItem.discountTotal +
                    singleItem.costTotal <
                  Number(orderedPricesList[0].calculatedPrice) - 1
                ) {
                  notValidItems.push(singleItem);
                }
              }
            }
          }
        }

        if (singleItem.article && singleItem.presentation === "UB") {
          if (singleItem.price < singleItem.article.COSTO_MIN_VTA - 1) {
            notValidItems.push(singleItem);
          }
        }
      }

      if (notValidItems.length > 0) {
        const uniqueNotValidItems = Array.from(
          new Set(notValidItems.map((o) => JSON.stringify(o)))
        ).map((str) => JSON.parse(str));

        this.discountErrorMesage +=
          "Estos artículos son los que tienen error: \n";
        for (const uniqueNotValidItem of uniqueNotValidItems) {
          this.discountErrorMesage += `${uniqueNotValidItem.article.CLAVE_ART} \n`;
        }
      }

      return !notValidItems.length > 0;
    },
    transformSale(sale) {
      let transformedSale = {};
      transformedSale.TIPO = sale.TIPO;
      transformedSale.OBSERVA = sale.OBSERVA;
      transformedSale.OBSERVA_IMP = sale.OBSERVA_IMP;
      transformedSale.FECHA = sale.FECHA;
      transformedSale.CLAVE_ALMACEN = sale.ALMACEN;
      transformedSale.CLAVE_CLIENTE = sale.CLIENTE;
      transformedSale.TOTAL = sale.total;
      transformedSale.SUBTOTAL = sale.subtotal;
      transformedSale.IVA = sale.iva;
      transformedSale.FLETE = sale.shippingQuantity;
      transformedSale.PORC_FLETE = sale.shippingPercentage;
      transformedSale.EXENTO = sale.EXENTO;
      transformedSale.IEPS = sale.ieps;
      transformedSale.COSTOS = sale.COSTOS;
      transformedSale.DESCS = sale.DESCS;
      transformedSale.COST_GTO = sale.costs;
      transformedSale.DESC_GTO = sale.discounts;
      transformedSale.IMPRESO = sale.printTimes;
      transformedSale.STATUS = "AC";
      transformedSale.STATUS_EN = sale.sendMaterial ? "CO" : "PE";
      transformedSale.CONDICION_P = sale.CONDICION_P;
      transformedSale.METODO_P = sale.METODO_P;
      transformedSale.PERI_PAGO = sale.PERI_PAGO;
      transformedSale.POS = sale.POS;
      transformedSale.CLAVE_USO_SAT = sale.CLAVE_USO_SAT;
      transformedSale.CLAVE_REGIMEN = sale.CLAVE_REGIMEN;
      transformedSale.FORMA_P = sale.FORMA_P;
      transformedSale.NO_CUENTA = sale.NO_CUENTA;
      transformedSale.NO_CLABE = sale.NO_CLABE;
      transformedSale.NO_TARJETA = sale.NO_TARJETA;
      transformedSale.NO_CHEQUE = sale.NO_CHEQUE;
      transformedSale.requireInvoice = sale.requireInvoice;
      transformedSale.requireInvoiceAfterSend = sale.requireInvoiceAfterSend;
      transformedSale.requireParcialInvoiceAfterSend =
        sale.requireParcialInvoiceAfterSend;
      transformedSale.UUID_CFDI = sale.UUID_CFDI;
      transformedSale.TIPO_R = sale.TIPO_R;
      transformedSale.relateCFDI = sale.relateCFDI;
      transformedSale.divideCFDI = sale.divideCFDI;
      transformedSale.VENDEDOR = sale.VENDEDOR;
      transformedSale.RAZON_SOCIAL = sale.RAZON_SOCIAL;
      transformedSale.DESBLOQUEO_CLIENTE = sale.DESBLOQUEO_CLIENTE;
      transformedSale.DESBLOQUEO_ALMACEN = sale.DESBLOQUEO_ALMACEN;
      transformedSale.sendMaterial = sale.sendMaterial;
      transformedSale.RESTA = sale.sendMaterial ? 0 : sale.total;
      transformedSale.N_PACRIALIDAD = 1;
      transformedSale.useAdvancedPayment = sale.useAdvancedPayment;
      transformedSale.CLAVE_ANTICIPO = sale.CLAVE_ANTICIPO;
      transformedSale.CANTIDAD_ANTICIPO = sale.CANTIDAD_ANTICIPO;
      transformedSale.UTILIDAD = sale.UTILIDAD;
      transformedSale.FECHA_ENVIO = sale.FECHA_ENVIO;
      transformedSale.clientPickup = sale.clientPickup;
      transformedSale.OC_CLIENTE = sale.OC_CLIENTE;
      transformedSale.USUARIO_CREACION = sale.USUARIO_CREACION;
      transformedSale.USUARIO_CONFIRMACION = sale.USUARIO_CONFIRMACION;
      transformedSale.CLAVE_VEHICULO = sale.CLAVE_VEHICULO;
      transformedSale.CODIGO_ENVIO = sale.CODIGO_ENVIO;

      if (sale._id) transformedSale._id = sale._id;
      let items = [];
      for (let singleItem of sale.currentItems) {
        if (singleItem.article) {
          const transformedItem = {
            CLAVE_ART: singleItem.article._id,
            PRESENTACION: singleItem.presentation,
            CANTIDAD_PRESENTACION: singleItem.CANTIDAD_PRESENTACION,
            CONCEP_IE: singleItem.article.CLAVE_ART,
            DESCRIBEAR: singleItem.article.CLAVE_ART,
            LISTA_PRECIOS: singleItem.priceList,
            RENGLON: singleItem.RENGLON,
            CANTIDAD_PE: singleItem.quantity,
            CANTIDAD_EN: sale.sendMaterial ? singleItem.quantity : 0,
            CANTIDAD_FE: sale.sendMaterial ? 0 : singleItem.quantity,
            PORC_IVA: this.getIva(singleItem),
            PORC_IEPS: this.getIeps(singleItem),
            IVA_GTO: singleItem.IVA_GTO,
            IEPS_GTO: singleItem.IEPS_GTO,
            TOTAL: singleItem.finalPrice,
            COSTO_UNI: singleItem.price,
            DESC_GTO: singleItem.discountTotal,
            COST_GTO: singleItem.costTotal,
            DESCS: singleItem.discounts,
            COSTOS: singleItem.costs,
            EXENTO: singleItem.EXENTO,
            SUB_TOT: singleItem.subTotal,
            COSTO_MIN_VTA: singleItem.COSTO_MIN_VTA,
            UTILIDAD_UNI: singleItem.utility,
            UTILIDAD: singleItem.utilityTotal,
            RESTA: sale.sendMaterial ? 0 : singleItem.finalPrice,
          };
          if (singleItem._id) transformedItem._id = singleItem._id;
          items.push(transformedItem);
        }
      }
      transformedSale.items = items;
      return transformedSale;
    },
    addRow() {
      this.currentIndex++;
      const newRow = {
        article: null,
        presentation: "",
        quantity: 1,
        RENGLON: this.currentIndex,
        subTotal: 0,
        price: 0,
        finalPrice: 0,
        costTotal: 0,
        discountTotal: 0,
        costs: [],
        discounts: [],
        selectedOption: "",
        priceList: "",
      };
      this.newSale.currentItems.push(newRow);
    },
    openAddGlobalCost() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalCost,
        props: {
          allCosts: this.newSale.COSTOS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddGlobalDiscount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalDiscount,
        props: {
          allDiscounts: this.newSale.DESCS,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    changeOption(product, index) {
      product.discounts.forEach((discount) => {
        if (discount.type == "Porcentaje") {
          this.newSale.currentItems[index].discounts +=
            product.currentPrice * (discount.quantity / 100);
        } else if (discount.type == "Fijo") {
          this.newSale.currentItems[index].discounts += discount.quantity;
        }
      });
      product.costs.forEach((cost) => {
        if (cost.type == "Porcentaje") {
          this.newSale.currentItems[index].costs +=
            product.currentPrice * (cost.quantity / 100);
        } else if (cost.type == "Fijo") {
          this.newSale.currentItems[index].costs += cost.quantity;
        }
      });
      this.newSale.currentItems[index].price = product.currentPrice;
    },
    getWeightTotal(article) {
      if (article) {
        let foundItem;
        let totalWeight = 0;
        for (const [
          singleItemIndex,
          singleItem,
        ] of this.newSale.currentItems.entries()) {
          if (
            singleItem.article &&
            singleItem.article.CLAVE_ART === article.CLAVE_ART
          ) {
            if (article.CANT_PESO) {
              totalWeight += Number(singleItem.quantity) * article.CANT_PESO;
            }
          }
        }
        return totalWeight;
      }
      return 0;
    },
    getTotalWeight() {
      let totalWeight = 0;
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newSale.currentItems.entries()) {
        if (
          singleArticle.article &&
          singleArticle.article &&
          singleArticle.article.CANT_PESO
        ) {
          totalWeight +=
            this.newSale.currentItems[singleArticleIndex].quantity *
            singleArticle.article.CANT_PESO;
        }
      }
      return totalWeight;
    },
    getIva(item) {
      if (item.article) {
        if (
          item.article.CANT_IVA !== undefined &&
          item.article.CANT_IVA !== null
        ) {
          return item.article.CANT_IVA;
        } else {
          return 16;
        }
      }
      return 0;
    },
    getIeps(item) {
      if (item.article) {
        if (
          item.article.CANT_IEPS !== undefined &&
          item.article.CANT_IEPS !== null
        ) {
          return item.article.CANT_IEPS;
        } else {
          return 0;
        }
      }
      return 0;
    },
    deleteRow(item) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.RENGLON === item.RENGLON) {
          this.newSale.currentItems.splice(singleItemIndex, 1);
        }
      }
      this.updateTotals();
    },
    openAddCost(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCost,
        props: {
          isSale: true,
          isGeneral: true,
          item: item,
          extraInfo: item,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddDiscount(item) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddDiscount,
        props: {
          isSale: true,
          isGeneral: true,
          item: item,
          extraInfo: item,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddShippingCost(sale) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddShippingCost,
        props: {
          shippingQuantity: sale.shippingQuantity,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    handleOptionChange(item) {
      if (item.selectedOption === "deleteItem") {
        this.deleteRow(item);
      } else if (item.selectedOption === "discount") {
        this.openAddDiscount(item);
      } else if (item.selectedOption === "cost") {
        this.openAddCost(item);
      }
      setTimeout(() => {
        item.selectedOption = "";
      }, 500);
    },
    addItemDiscount(item, discounts, rowInformation) {
      // Move trough the items and find the one were the discounts were added
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (
          singleItem.article._id === item._id &&
          singleItem.presentation === rowInformation.presentation
        ) {
          // sets discounts to the one selected
          this.newSale.currentItems[singleItemIndex].discounts = discounts;
          break;
        }
      }

      this.updateTotals();
    },
    addItemCost(item, costs, rowInformation) {
      // Move trough the items and find the one were the cost were added
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (
          singleItem.article._id === item._id &&
          singleItem.presentation === rowInformation.presentation
        ) {
          // sets costs to the one selected
          this.newSale.currentItems[singleItemIndex].costs = costs;
          break;
        }
      }

      // Update totals
      this.updateTotals();
    },
    /**
     *
     * @desc gets the current inventory for an article
     */
    getInventoryWarehouse(article) {
      for (const singleArticle of this.articles) {
        if (singleArticle._id === article._id) {
          for (const singleWarehouse of singleArticle.CANT_ALMACENES) {
            if (singleWarehouse.CLAVE_ALMACEN === this.newSale.ALMACEN) {
              let totalQuantity = round(Number(singleWarehouse.CANTIDAD), 4);
              if (singleWarehouse.CANTIDAD_VENTAS) {
                totalQuantity += round(
                  Number(singleWarehouse.CANTIDAD_VENTAS),
                  4
                );
              }

              if (singleWarehouse.CANTIDAD_VIRTUAL) {
                totalQuantity += round(
                  Number(singleWarehouse.CANTIDAD_VIRTUAL),
                  4
                );
              }
              return totalQuantity;
            }
          }
        }
      }
      return 0;
    },
    /**
     *
     * @desc updates totals of the new sale and calculates taxes from all products
     */
    updateTotals() {
      let total = 0;
      let subtotal = 0;
      let subtotalNoExtra = 0;
      let iva = 0;
      let ieps = 0;
      let cost = 0;
      let costsTotal = 0;
      let discount = 0;
      let discountsTotal = 0;
      let excent = 0;
      let utilityTotal = 0;

      this.newSale.shippingQuantity = round(
        Number(this.newSale.shippingQuantity).toFixed(5),
        2
      );

      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newSale.currentItems.entries()) {
        if (singleItem.article) {
          let costItem = 0;
          let discountItem = 0;

          let priceQuantity =
            toFixedNoRound(singleItem.price, 3) * singleItem.quantity;

          for (const cost of singleItem.costs) {
            costItem += cost.CANTIDAD;
          }

          costsTotal += singleItem.quantity * costItem;

          for (const discount of singleItem.discounts) {
            discountItem += discount.CANTIDAD;
          }

          discountsTotal += singleItem.quantity * discountItem;

          let subTotalItem =
            priceQuantity +
            singleItem.quantity * costItem -
            singleItem.quantity * discountItem;

          subtotal += subTotalItem;
          subtotalNoExtra += priceQuantity;

          let totalItem = 0;
          totalItem += subTotalItem;

          // Add Iva to the total
          let itemIva = subTotalItem * (this.getIva(singleItem) / 100);
          totalItem += itemIva;
          iva += itemIva;

          // Add Ieps to the total
          let itemIeps = subTotalItem * (this.getIeps(singleItem) / 100);
          totalItem += itemIeps;
          ieps += itemIeps;

          // Add iva excent to excent total
          if (singleItem.article && singleItem.article.TIPO_IVA === "EX") {
            excent += priceQuantity;
          }

          // Set single item COSTO_MIN_VTA to the current COSTO_MIN_VTA of the article
          this.newSale.currentItems[singleItemIndex].COSTO_MIN_VTA =
            singleItem.article.COSTO_MIN_VTA * singleItem.CANTIDAD_PRESENTACION;

          this.newSale.currentItems[singleItemIndex].subTotal = toFixedNoRound(
            subTotalItem,
            3
          );
          this.newSale.currentItems[singleItemIndex].discountTotal =
            toFixedNoRound(discountItem, 3);
          this.newSale.currentItems[singleItemIndex].costTotal = toFixedNoRound(
            costItem,
            3
          );
          this.newSale.currentItems[singleItemIndex].finalPrice =
            toFixedNoRound(totalItem, 3);
          this.newSale.currentItems[singleItemIndex].IVA_GTO = toFixedNoRound(
            itemIva,
            3
          );
          this.newSale.currentItems[singleItemIndex].IEPS_GTO = toFixedNoRound(
            itemIeps,
            3
          );

          this.newSale.currentItems[singleItemIndex].utility = toFixedNoRound(
            Number(singleItem.price) +
              costItem -
              discountItem -
              Number(
                singleItem.article.COSTO_MIN_VTA *
                  singleItem.CANTIDAD_PRESENTACION
              ),
            3
          );

          this.newSale.currentItems[singleItemIndex].utilityTotal =
            toFixedNoRound(
              this.newSale.currentItems[singleItemIndex].utility *
                singleItem.quantity,
              3
            );

          utilityTotal +=
            this.newSale.currentItems[singleItemIndex].utilityTotal;
        }
      }

      /*
      let currentSubtotal = subtotal;

      for (let singleDiscount of this.newSale.DESCS) {
        if (singleDiscount.TIPO === "PO") {
          let discountQuantity =
            currentSubtotal * (singleDiscount.PORCENTAJE / 100);
          currentSubtotal -= discountQuantity;
          discount += discountQuantity;
          discountsTotal += discountQuantity;
        } else if (singleDiscount.TIPO === "CA") {
          discount += Number(singleDiscount.CANTIDAD);
          discountsTotal += Number(singleDiscount.CANTIDAD);
        }
      }

      for (let singleCost of this.newSale.COSTOS) {
        if (singleCost.TIPO === "PO") {
          let costQuantity = currentSubtotal * (singleCost.PORCENTAJE / 100);
          currentSubtotal -= costQuantity;
          cost += costQuantity;
          costsTotal += costQuantity;
        } else if (singleCost.TIPO === "CA") {
          cost += Number(singleCost.CANTIDAD);
          costsTotal += Number(singleCost.CANTIDAD);
        }
      }
      */

      total +=
        subtotal - discount + cost + iva + ieps + this.newSale.shippingQuantity;

      this.newSale.subtotal = toFixedNoRound(subtotalNoExtra, 3);
      this.newSale.total = toFixedNoRound(total, 3);
      this.newSale.discounts = toFixedNoRound(discountsTotal, 3);
      this.newSale.costs = toFixedNoRound(costsTotal, 3);
      this.newSale.iva = toFixedNoRound(iva, 3);
      this.newSale.ieps = toFixedNoRound(ieps, 3);
      this.newSale.EXENTO = toFixedNoRound(excent, 3);
      this.newSale.UTILIDAD = toFixedNoRound(utilityTotal, 3);
    },
    /**
     *
     * @desc gets and updates the article price according to the default price lists
     */
    getArticleCurrentPrice(item, clientInformation, skip = false) {
      item.price = 0;
      item.costTotal = 0;
      item.discountTotal = 0;
      item.costs = [];
      item.quantity = 1;
      item.discounts = [];
      item.EXENTO = "N";
      item.IVA_GTO = 0;
      item.IEPS_GTO = 0;
      item.CANTIDAD_PRESENTACION = 1;
      item.utility = 0;

      if (!skip) {
        // Check if presentations are available to use them as base
        if (!this.checkArticleRepeatedPresentation(item.article, "UB")) {
          for (const singlePresentation of item.article.PRESENTACIONES) {
            const repeatedPresentation = this.checkArticleRepeatedPresentation(
              item.article,
              singlePresentation._id
            );
            if (repeatedPresentation) {
              item.presentation = singlePresentation._id;
              this.getArticleCurrentPricePresentation(
                item,
                clientInformation,
                singlePresentation._id
              );
              return;
            }
          }
        } else {
          item.presentation = "UB";
        }
      }

      // Add the Merma to the calculation
      if (item.article.PORC_MERMA)
        item.price +=
          item.article.COSTO_MIN_VTA * (item.article.PORC_MERMA / 100);
      // Add other cost to the calculation
      if (item.article.PORC_INDIREC)
        item.price +=
          item.article.COSTO_MIN_VTA * (item.article.PORC_INDIREC / 100);
      // Add shipping cost to the calculation
      if (item.article.PORC_FLETE)
        item.price +=
          item.article.COSTO_MIN_VTA * (item.article.PORC_FLETE / 100);
      // Set exento if the item is exento
      if (item.article && item.article.TIPO_IVA === "EX") {
        item.EXENTO = "S";
      }

      item.price += item.article.COSTO_MIN_VTA;

      let found = false;
      if (item.article && clientInformation.LISTA_PRECIOS_PRE) {
        // Search for price ist in object
        for (const singlePriceList of item.article.LISTAS_PREC) {
          // Price list was found in article
          if (
            singlePriceList.CLAVE_LISTAP ===
            clientInformation.LISTA_PRECIOS_PRE._id
          ) {
            found = true;
            item.priceList = singlePriceList.CLAVE_LISTAP;
            // Calculate price with utility
            item.price +=
              (singlePriceList.PORC_UTILID / 100) * item.article.COSTO_MIN_VTA;
            item.utility =
              (singlePriceList.PORC_UTILID / 100) * item.article.COSTO_MIN_VTA;
            // Set discounts and costs
            item.costs = singlePriceList.COSTOS;
            item.discounts = singlePriceList.DESCUENTOS;
            break;
          }
        }

        // Price list was not found in article prices list
        if (!found) {
          for (const singlePriceList of this.priceLists) {
            if (
              singlePriceList._id === clientInformation.LISTA_PRECIOS_PRE._id
            ) {
              item.priceList = singlePriceList._id;
              // Calculate price with utility
              item.price +=
                (singlePriceList.PORC_UTILID / 100) *
                item.article.COSTO_MIN_VTA;
              item.utility =
                (singlePriceList.PORC_UTILID / 100) *
                item.article.COSTO_MIN_VTA;
              // Set discounts and costs
              item.costs = [];
              item.discounts = [];

              break;
            }
          }
        }
      } else {
        // Set default price list to the first list of the artcile
        if (item.article.LISTAS_PREC.length > 0) {
          item.priceList = item.article.LISTAS_PREC[0].CLAVE_LISTAP;
          item.price +=
            (item.article.LISTAS_PREC[0].PORC_UTILID / 100) *
            item.article.COSTO_MIN_VTA;
          item.utility =
            (item.article.LISTAS_PREC[0].PORC_UTILID / 100) *
            item.article.COSTO_MIN_VTA;
          item.costs = item.article.LISTAS_PREC[0].COSTOS;
          item.discounts = item.article.LISTAS_PREC[0].DESCUENTOS;
        } else {
          item.priceList = this.priceLists[0]._id;
          item.price +=
            (this.priceLists[0].PORC_UTILID / 100) * item.article.COSTO_MIN_VTA;
          item.utility =
            (this.priceLists[0].PORC_UTILID / 100) * item.article.COSTO_MIN_VTA;
          item.costs = [];
          item.discounts = [];
        }
      }

      item.price = toFixedNoRound(item.price, 3);
      this.updateTotals();
      return;
    },
    getArticleCurrentPricePresentation(item, clientInformation, presentation) {
      item.price = 0;
      item.costTotal = 0;
      item.discountTotal = 0;
      item.costs = [];
      item.quantity = 1;
      item.discounts = [];
      item.EXENTO = "N";
      item.IVA_GTO = 0;
      item.IEPS_GTO = 0;
      item.utility = 0;

      let currentPriceList = [];
      let currentMinimimSalePrice = 0;

      // Add the Merma to the calculation
      if (item.article.PORC_MERMA)
        item.price +=
          item.article.COSTO_MIN_VTA * (item.article.PORC_MERMA / 100);
      // Add other cost to the calculation
      if (item.article.PORC_INDIREC)
        item.price +=
          item.article.COSTO_MIN_VTA * (item.article.PORC_INDIREC / 100);
      // Add shipping cost to the calculation
      if (item.article.PORC_FLETE)
        item.price +=
          item.article.COSTO_MIN_VTA * (item.article.PORC_FLETE / 100);
      // Set exento if the item is exento
      if (item.article && item.article.TIPO_IVA === "EX") {
        item.EXENTO = "S";
      }

      for (const singlePresentation of item.article.PRESENTACIONES) {
        if (singlePresentation._id === presentation) {
          currentPriceList = singlePresentation.LISTAS_PREC;
          if (singlePresentation.ESMAYOR === "S") {
            item.price +=
              item.article.COSTO_MIN_VTA * singlePresentation.FACTOR2;
            currentMinimimSalePrice =
              item.article.COSTO_MIN_VTA * singlePresentation.FACTOR2;
            item.CANTIDAD_PRESENTACION = 1 * singlePresentation.FACTOR2;
          } else {
            item.price +=
              item.article.COSTO_MIN_VTA / singlePresentation.FACTOR2;
            currentMinimimSalePrice =
              item.article.COSTO_MIN_VTA / singlePresentation.FACTOR2;
            item.CANTIDAD_PRESENTACION = 1 / singlePresentation.FACTOR2;
          }
          break;
        }
      }

      let found = false;

      if (item.article && this.currentClientInformation.LISTA_PRECIOS_PRE) {
        // Search for price ist in object
        for (const singlePriceList of currentPriceList) {
          // Price list was found in article
          if (
            singlePriceList.CLAVE_LISTAP ===
            clientInformation.LISTA_PRECIOS_PRE._id
          ) {
            found = true;
            item.priceList = singlePriceList.CLAVE_LISTAP;
            // Calculate price with utility
            item.price +=
              (singlePriceList.PORC_UTILID / 100) * currentMinimimSalePrice;
            item.utility =
              (singlePriceList.PORC_UTILID / 100) * currentMinimimSalePrice;
            // Set discounts and costs
            item.costs = singlePriceList.COSTOS;
            item.discounts = singlePriceList.DESCUENTOS;
            break;
          }
        }

        // Price list was not found in article prices list
        if (!found) {
          for (const singlePriceList of this.priceLists) {
            if (
              singlePriceList._id === clientInformation.LISTA_PRECIOS_PRE._id
            ) {
              item.priceList = singlePriceList._id;
              // Calculate price with utility
              item.price +=
                (singlePriceList.PORC_UTILID / 100) * currentMinimimSalePrice;
              item.utility =
                (singlePriceList.PORC_UTILID / 100) * currentMinimimSalePrice;
              // Set discounts and costs
              item.costs = [];
              item.discounts = [];
              break;
            }
          }
        }
      } else {
        // Set default price list to the first list of the artcile
        if (item.article.LISTAS_PREC.length > 0) {
          item.priceList = item.article.LISTAS_PREC[0].CLAVE_LISTAP;
          item.price +=
            (item.article.LISTAS_PREC[0].PORC_UTILID / 100) *
            currentMinimimSalePrice;
          item.utility =
            (item.article.LISTAS_PREC[0].PORC_UTILID / 100) *
            currentMinimimSalePrice;
          item.costs = item.article.LISTAS_PREC[0].COSTOS;
          item.discounts = item.article.LISTAS_PREC[0].DESCUENTOS;
        } else {
          item.priceList = this.priceLists[0]._id;
          item.price +=
            (this.priceLists[0].PORC_UTILID / 100) * currentMinimimSalePrice;
          item.utility =
            (this.priceLists[0].PORC_UTILID / 100) * currentMinimimSalePrice;
          item.costs = [];
          item.discounts = [];
        }
      }

      item.price = toFixedNoRound(item.price, 3);
      this.updateTotals();
      return;
    },
    /**
     *
     * @desc changes the price information when article price list changes
     */
    changeArticlePriceList(item, value) {
      if (item.presentation === "UB") {
        for (const singlePriceList of item.article.defaultListPrices) {
          if (singlePriceList.CLAVE_LISTAP === item.priceList) {
            item.price = Number(singlePriceList.calculatedPrice);
            item.costs = singlePriceList.COSTOS;
            item.discounts = singlePriceList.DESCUENTOS;
          }
        }
      } else {
        for (const singlePresentation of item.article.PRESENTACIONES) {
          if (singlePresentation._id === item.presentation) {
            for (const singlePriceList of singlePresentation.defaultListPrices) {
              if (singlePriceList.CLAVE_LISTAP === item.priceList) {
                item.price = Number(singlePriceList.calculatedPrice);
                item.costs = singlePriceList.COSTOS;
                item.discounts = singlePriceList.DESCUENTOS;
              }
            }
          }
        }
      }

      this.updateTotals();
    },
    checkArticleRepeated(article) {
      // Available presentations for article
      let availablePresentations = article.PRESENTACIONES.length + 1;

      // Substract presentations already used
      for (const singleItem of this.newSale.currentItems) {
        if (singleItem.article && singleItem.article._id === article._id) {
          availablePresentations -= 1;
        }
      }

      if (availablePresentations > 0) {
        return true;
      } else {
        return false;
      }
    },
    checkArticleRepeatedPresentation(article, presentation) {
      // Substract presentations already used
      for (const singleItem of this.newSale.currentItems) {
        if (
          singleItem.article &&
          singleItem.article._id === article._id &&
          singleItem.presentation === presentation
        ) {
          return false;
        }
      }
      return true;
    },
    checkArticlesQuantityWarning() {
      // Substract presentations already used
      for (const singleItem of this.newSale.currentItems) {
        if (singleItem.article && singleItem.quantity) {
          if (
            this.getInventoryWarehouse(singleItem.article) < singleItem.quantity
          ) {
            return false;
          }
        }
      }
      return true;
    },
    canConfirmQuoteCredit(saleInformation) {
      if (
        saleInformation.CONDICION_P === "R" &&
        !saleInformation.DESBLOQUEO_CLIENTE
      ) {
        return (
          this.selectedClientInformation.remainingCredit >=
          saleInformation.total
        );
      }
      return true;
    },
    async confirmQuote(sale) {
      try {
        if (!this.canConfirmQuoteCredit(sale)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al confirmar cotización revise la línea de crédito disponible`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        const editQuoteResponse = await this.saveNewSale(1, false);

        if (editQuoteResponse !== "Success") {
          return;
        }

        let response = await this.$store.dispatch("CONFIRMQUOTE", {
          _id: sale._id,
        });

        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se confirmó exitosamente la cotización",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al confirmar la cotización en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al confirmar la cotización en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async handleClientChange() {
      if (this.newSale.CLIENTE) {
        for (const singleClient of this.clients) {
          if (singleClient._id === this.newSale.CLIENTE) {
            this.currentClientInformation = singleClient;
            if (singleClient.CLAVE_USO_SAT) {
              this.newSale.CLAVE_USO_SAT = singleClient.CLAVE_USO_SAT;
            }
            if (singleClient.CLAVE_REGIMEN) {
              this.newSale.CLAVE_REGIMEN = singleClient.CLAVE_REGIMEN;
            }
            if (singleClient.CLAVE_REGIMEN) {
              this.newSale.CLAVE_REGIMEN = singleClient.CLAVE_REGIMEN;
            }
            if (singleClient.CLAVE_ALMACEN) {
              this.newSale.ALMACEN = singleClient.CLAVE_ALMACEN;
            }
            if (singleClient.CONDICION_P) {
              this.newSale.CONDICION_P = singleClient.CONDICION_P;
            }
            if (singleClient.METODO_P) {
              this.newSale.METODO_P = singleClient.METODO_P;
            }
            if (singleClient.FORMA_P) {
              this.newSale.FORMA_P = singleClient.FORMA_P;
            }
            if (singleClient.D_CREDITO && this.newSale.CONDICION_P === "R") {
              this.newSale.PERI_PAGO = singleClient.D_CREDITO;
            }
            if (singleClient.CLAVE_VENDEDOR) {
              this.newSale.VENDEDOR = singleClient.CLAVE_VENDEDOR;
            }

            if (singleClient.CLAVE_AGRUPACION_V2) {
              let creditResponse = await this.$store.dispatch(
                "GETCLIENTV2CREDITLIMIT",
                {
                  groupId: singleClient.CLAVE_AGRUPACION_V2,
                }
              );

              if (creditResponse.creditLitmit) {
                this.selectedCLientCreditInformation = { ...creditResponse };
              }
            }

            break;
          }
        }
        this.newSale.currentItems = [];
        this.addRow();
        this.updateTotals();
      }
    },
    handlePaymentConditionChange() {
      this.newSale.PERI_PAGO = 0;
      // Change sale information to the one in client object
      if (this.newSale.CLIENTE) {
        for (const singleClient of this.clients) {
          if (singleClient._id === this.newSale.CLIENTE) {
            if (singleClient.D_CREDITO && this.newSale.CONDICION_P === "R") {
              this.newSale.PERI_PAGO = singleClient.D_CREDITO;
            }
            if (singleClient.CLAVE_USO_SAT) {
              this.newSale.CLAVE_USO_SAT = singleClient.CLAVE_USO_SAT;
            }
            if (singleClient.CLAVE_REGIMEN) {
              this.newSale.CLAVE_REGIMEN = singleClient.CLAVE_REGIMEN;
            }
            break;
          }
        }
      }

      // Change Método de pago according to empty
      if (this.newSale.CONDICION_P === "C") {
        this.newSale.FORMA_P = "";
      }

      // Change Método de pago according to payment condition
      if (this.newSale.CONDICION_P === "R") {
        this.newSale.FORMA_P = this.porDefinirPaymentId;
        this.newSale.METODO_P = "D";
      }

      this.handleAdvancedPaymentSelection();
    },
    handlePaymentMethodChange() {
      if (this.newSale.METODO_P === "D") {
        if (this.newSale.CLIENTE) {
          for (const singleClient of this.clients) {
            if (singleClient._id === this.newSale.CLIENTE) {
              if (singleClient.D_CREDITO && this.newSale.CONDICION_P === "R") {
                this.newSale.PERI_PAGO = singleClient.D_CREDITO;
              }

              break;
            }
          }
        }
        this.newSale.CONDICION_P = "R";
        this.newSale.FORMA_P = this.porDefinirPaymentId;
      } else {
        this.newSale.PERI_PAGO = 0;
        this.FORMA_P =
          this.newSale.CONDICION_P === "R" ? this.porDefinirPaymentId : "";
      }
    },
    handlePaymentFormChange() {
      if (this.newSale.divideCFDI) {
        this.newSale.divideCFDI = false;
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startConfirmationEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    checkRepeatedArticle(article, sale) {
      for (const singleItem of sale.currentItems) {
        if (singleItem.article && singleItem.article._id === article._id) {
          return true;
        }
      }
      return false;
    },
    openSearchArticle(itemInformation, itemIndex) {
      const notRepeatedArticles = [];
      for (const singleArticle of this.articles) {
        if (!this.checkRepeatedArticle(singleArticle, this.newSale)) {
          notRepeatedArticles.push(singleArticle);
        }
      }
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectArticle,
        props: {
          isSale: true,
          articles: notRepeatedArticles,
          priceLists: this.priceLists,
          RENGLON: itemIndex,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openSearchClientUnlock(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SearchClientUnlock,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openSearchWarehouseUnlock(saleInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SearchWarehouseUnlock,
        props: {
          saleInformation: saleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    autoCompleteClients(value) {
      if (!this.clients) {
        return [];
      }
      return this.clients.filter((singleClient) => {
        return (
          singleClient.NOMBRE_EMPRESA.toLowerCase().indexOf(
            value.toLowerCase()
          ) >= 0 ||
          singleClient.CLAVE_CLI.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    getClientRFC() {
      if (this.newSale.CLIENTE) {
        for (const singleClient of this.clients) {
          if (singleClient._id === this.newSale.CLIENTE) {
            return singleClient.RFC_CLI;
          }
        }
      }

      return "";
    },
    handleUseAdvancedPayment() {
      if (!this.newSale.useAdvancedPayment) {
        this.newSale.relateCFDI = false;
        this.newSale.CLAVE_ANTICIPO = undefined;
        this.newSale.CANTIDAD_ANTICIPO = 0;
        this.currentAdvancedPaymentInformation = null;
        this.newSale.UUID_CFDI = "";
        this.newSale.TIPO_R = "";
      }

      if (this.newSale.useAdvancedPayment) {
        this.newSale.relateCFDI = true;
      }
    },
    handleRequireInvoice() {
      this.newSale.divideCFDI = false;
      this.newSale.useAdvancedPayment = false;
      this.newSale.relateCFDI = false;
      this.newSale.CLAVE_ANTICIPO = undefined;
      this.newSale.CANTIDAD_ANTICIPO = 0;
      this.currentAdvancedPaymentInformation = null;
      this.newSale.UUID_CFDI = "";
      this.newSale.TIPO_R = "";
      this.requireInvoiceAfterSend = false;
      this.requireParcialInvoiceAfterSend = false;
    },
    handleRequireInvoiceAfterSend() {
      this.newSale.divideCFDI = false;
      this.newSale.useAdvancedPayment = false;
      this.newSale.relateCFDI = false;
      this.newSale.CLAVE_ANTICIPO = undefined;
      this.newSale.CANTIDAD_ANTICIPO = 0;
      this.currentAdvancedPaymentInformation = null;
      this.newSale.UUID_CFDI = "";
      this.newSale.TIPO_R = "";
      this.newSale.requireInvoice = false;
      this.requireParcialInvoiceAfterSend = false;
    },
    handleRequireParcialInvoiceAfterSend() {
      this.newSale.divideCFDI = false;
      this.newSale.useAdvancedPayment = false;
      this.newSale.relateCFDI = false;
      this.newSale.CLAVE_ANTICIPO = undefined;
      this.newSale.CANTIDAD_ANTICIPO = 0;
      this.currentAdvancedPaymentInformation = null;
      this.newSale.UUID_CFDI = "";
      this.newSale.TIPO_R = "";
      this.newSale.requireInvoice = false;
      this.requireInvoiceAfterSend = false;
    },
    handleAdvancedPaymentSelection() {
      for (const singlePayment of this.advancedPayment) {
        if (singlePayment._id === this.newSale.CLAVE_ANTICIPO) {
          this.currentAdvancedPaymentInformation = singlePayment;
          this.newSale.UUID_CFDI = singlePayment.PAGO_CFDI
            ? singlePayment.PAGO_CFDI.UUID
            : "";
          this.newSale.TIPO_R = singlePayment.PAGO_CFDI ? "07" : "";

          if (this.newSale.CONDICION_P === "R") {
            this.newSale.NO_CUENTA = singlePayment.NO_CUENTA;
            this.newSale.NO_CLABE = singlePayment.NO_CLABE;
            this.newSale.NO_TARJETA = singlePayment.NO_TARJETA;
            this.newSale.NO_CHEQUE = singlePayment.NO_CHEQUE;
          }
        }
      }
    },
    checkCanDivideCashInvoice() {
      let canDivideInvoice = false;

      for (const singleItem of this.newSale.currentItems) {
        if (singleItem.article && singleItem.quantity >= 1) {
          canDivideInvoice = true;
        }
      }

      for (const singleItem of this.newSale.currentItems) {
        if (singleItem.price > 1999) {
          canDivideInvoice = false;
        }
      }

      if (this.newSale.total > 100000) {
        this.newSale.divideCFDI = false;
        return false;
      }

      return (
        canDivideInvoice &&
        this.newSale.CLIENTE &&
        this.newSale.FORMA_P === this.efectivoPaymentId &&
        this.newSale.total >= 2000 &&
        this.checkIfCanInvoice(this.newSale.CLIENTE) &&
        this.userPermissions &&
        this.userPermissions.accounting.saveInvoice === "S" &&
        (this.newSale.requireInvoice || this.newSale.requireInvoiceAfterSend) &&
        !this.newSale.requireParcialInvoiceAfterSend
      );
    },
    /**
     *
     * @param currentArticle current article to get the minimum sale cost
     * @param utility the utility to use
     * @param change if set to true this will change the article object
     * @param index the index of the price list
     * @param onlyMainArticle if its the main article or a presentation
     * @param presentation the presentation info
     * @returns {string} the final price calculation
     */
    getFinalPrice(
      currentArticle,
      utility,
      change = false,
      index,
      onlyMainArticle = true,
      presentation = undefined
    ) {
      let finalMinimumCost = 0;
      let IVAQuantity = 0;
      let IEPSQuantity = 0;
      if (currentArticle.COSTO_MIN_VTA) {
        if (onlyMainArticle) {
          // Add the utility margin to the calculation for article
          let { COSTO_MIN_VTA } = { ...currentArticle };
          finalMinimumCost =
            Number(COSTO_MIN_VTA) + COSTO_MIN_VTA * (utility / 100);

          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
        } else {
          // Add the utility margin to the calculation for presentation
          let { COSTO_MIN_VTA } = { ...currentArticle };
          let { COSTO_MIN_VTA_PRESENT } = { ...currentArticle };
          // Add the Merma to the calculation
          if (currentArticle.PORC_MERMA)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_MERMA / 100);
          // Add other cost to the calculation
          if (currentArticle.PORC_INDIREC)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_INDIREC / 100);
          // Add shipping cost to the calculation
          if (currentArticle.PORC_FLETE)
            finalMinimumCost +=
              COSTO_MIN_VTA * (currentArticle.PORC_FLETE / 100);
          if (presentation.ESMAYOR === "S") {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA * presentation.FACTOR2;
          } else {
            COSTO_MIN_VTA_PRESENT = COSTO_MIN_VTA / presentation.FACTOR2;
          }
          finalMinimumCost +=
            Number(COSTO_MIN_VTA_PRESENT) +
            COSTO_MIN_VTA_PRESENT * (utility / 100);
        }
      }
      if (change) {
        if (!onlyMainArticle) {
          for (const [
            singlePresentationIndex,
            singlePresentation,
          ] of currentArticle.PRESENTACIONES.entries()) {
            if (singlePresentation._id === presentation._id) {
              currentArticle.PRESENTACIONES[
                singlePresentationIndex
              ].defaultListPrices[index].calculatedPrice =
                Number(finalMinimumCost).toFixed(5);
            }
          }
          // Force rendering changing an atribute on the articles list, in this case we change forceRendering property
          currentArticle.defaultListPrices[index].forceRendering += 1;
        } else {
          currentArticle.defaultListPrices[index].calculatedPrice =
            Number(finalMinimumCost).toFixed(5);
        }
      }
      return Number(finalMinimumCost.toString()).toFixed(5);
    },
    /**
     *
     * @desc sends the sale information to the component that opened this component
     */
    selectSale() {
      EventBus.$emit("saleSelected", {
        sale: this.purchaseSale,
      });
      this.closeModal();
    },
    getRandomString(length) {
      return createRandomStringCamps(length);
    },
    getArticleDefaultListPrices(articleInformation) {
      let articleWithProps = { ...articleInformation };

      let defaultListPrices = [];

      for (let singleListPresentation of articleWithProps.PRESENTACIONES) {
        singleListPresentation.defaultListPrices = [];
      }

      /**
       * Add default list prices
       */
      for (let singlePriceLists of this.priceLists) {
        let foundPriceList = false;

        //Searching in article price lists
        for (let singleArticlePriceList of articleInformation.LISTAS_PREC) {
          if (singlePriceLists._id === singleArticlePriceList.CLAVE_LISTAP) {
            // Calculate final price
            let finalPriceResult = this.getFinalPrice(
              articleInformation,
              singleArticlePriceList.PORC_UTILID
            );
            defaultListPrices.push({
              _id: singleArticlePriceList._id,
              CLAVE_LISTAP: singleArticlePriceList.CLAVE_LISTAP,
              NOMBRE_LISTAPT: singleArticlePriceList.NOMBRE_LISTAPT,
              PORC_UTILID: singleArticlePriceList.PORC_UTILID,
              COSTOS: singleArticlePriceList.COSTOS,
              DESCUENTOS: singleArticlePriceList.DESCUENTOS,
              calculatedPrice: finalPriceResult,
              forceRendering: 0,
            });
            foundPriceList = true;
          }
        }

        //Searching in article presentation price lists
        for (let singleListPresentation of articleInformation.PRESENTACIONES) {
          let foundPriceListPresentation = false;
          if (singleListPresentation.LISTAS_PREC) {
            for (let singlePriceListPresentation of singleListPresentation.LISTAS_PREC) {
              if (
                singlePriceLists._id ===
                singlePriceListPresentation.CLAVE_LISTAP
              ) {
                singleListPresentation.defaultListPrices.push({
                  _id: singlePriceListPresentation._id,
                  CLAVE_LISTAP: singlePriceListPresentation.CLAVE_LISTAP,
                  NOMBRE_LISTAPT: singlePriceListPresentation.NOMBRE_LISTAPT,
                  PORC_UTILID: singlePriceListPresentation.PORC_UTILID,
                  calculatedPrice: this.getFinalPrice(
                    articleInformation,
                    singlePriceListPresentation.PORC_UTILID,
                    false,
                    0,
                    false,
                    singleListPresentation
                  ),
                  COSTOS: singlePriceListPresentation.COSTOS,
                  DESCUENTOS: singlePriceListPresentation.DESCUENTOS,
                  forceRendering: 0,
                });
                foundPriceListPresentation = true;
              }
            }
          }

          //If article presentation price list was not found
          if (!foundPriceListPresentation) {
            if (articleInformation.COSTO_MIN_VTA) {
              let formatedPriceList = { ...singlePriceLists };
              formatedPriceList.NOMBRE_LISTAPT = singlePriceLists.CLAVE_LISTAP;
              formatedPriceList.CLAVE_LISTAP = singlePriceLists._id;
              delete formatedPriceList._id;
              formatedPriceList.calculatedPrice = this.getFinalPrice(
                articleInformation,
                formatedPriceList.PORC_UTILID,
                false,
                0,
                false,
                singleListPresentation
              );
              formatedPriceList.forceRendering = 0;
              formatedPriceList.COSTOS = [];
              formatedPriceList.DESCUENTOS = [];
              singleListPresentation.defaultListPrices.push(formatedPriceList);
            }
          }
        }

        //If article price list was not found
        if (!foundPriceList) {
          let formatedPriceList = { ...singlePriceLists };
          let finalPriceResult = 0;

          if (articleInformation.COSTO_MIN_VTA) {
            finalPriceResult = this.getFinalPrice(
              articleInformation,
              formatedPriceList.PORC_UTILID
            );
          }

          formatedPriceList.NOMBRE_LISTAPT = singlePriceLists.CLAVE_LISTAP;
          formatedPriceList.CLAVE_LISTAP = singlePriceLists._id;
          delete formatedPriceList._id;
          formatedPriceList.calculatedPrice = finalPriceResult;
          formatedPriceList.forceRendering = 0;
          formatedPriceList.COSTOS = [];
          formatedPriceList.DESCUENTOS = [];
          defaultListPrices.push(formatedPriceList);
        }
      }

      return defaultListPrices;
    },
    openAddMultipleArticles() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectMultipleArticlesSales,
        props: {
          currentArticles: this.newSale.currentItems,
          priceLists: this.priceLists,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };

        const defaultListPrices =
          this.getArticleDefaultListPrices(singleArticle);

        articleWithProps.defaultListPrices = defaultListPrices;

        return articleWithProps;
      })
        .filter((singleArticle) => {
          return (
            singleArticle.ACTIVO_VENTA ||
            singleArticle.ACTIVO_VENTA === undefined
          );
        })
        .sort((a, b) => {
          return a.DESCRIPCION > b.DESCRIPCION ? 1 : -1;
        });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        const singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      }).sort(function (a, b) {
        return a.CLAVE_LISTAP.localeCompare(b.CLAVE_LISTAP, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
    },
    lastSaleNumber() {
      return this.$store.getters.LASTSALENUMBER;
    },
    clientComputed() {
      return this.newSale.CLIENTE;
    },
    checkDisable() {
      if (this.newSale.CONDICION_P === "R") {
        if (
          this.newSale.CLIENTE &&
          this.newSale.ALMACEN &&
          this.newSale.CONDICION_P &&
          this.newSale.CLAVE_REGIMEN &&
          this.newSale.CLAVE_USO_SAT &&
          this.newSale.PERI_PAGO !== "" &&
          this.newSale.METODO_P !== ""
        ) {
          return false;
        }
      } else if (this.newSale.CONDICION_P === "C") {
        if (
          this.newSale.CLIENTE &&
          this.newSale.ALMACEN &&
          this.newSale.CONDICION_P &&
          this.newSale.CLAVE_REGIMEN &&
          this.newSale.CLAVE_USO_SAT &&
          this.newSale.PERI_PAGO !== "" &&
          this.newSale.METODO_P !== "" &&
          this.newSale.FORMA_P !== ""
        ) {
          return false;
        }
      }
      return true;
    },
    checkDisableItems() {
      for (let singleItem of this.newSale.currentItems) {
        if (singleItem.article) return false;
      }
      return true;
    },
    creationDateFomatted() {
      return moment(this.newSale.FECHA).format("ll");
    },
    selectedClientInformation() {
      for (const singleClient of this.clients) {
        if (this.newSale.CLIENTE === singleClient._id) {
          const singleClientWithProps = { ...singleClient };

          if (singleClient.CLAVE_AGRUPACION_V2) {
            singleClientWithProps.remainingCredit =
              this.selectedCLientCreditInformation.remainingBalance +
              (this.unlockClientInformation &&
              this.unlockClientInformation.CANTIDAD
                ? Number(this.unlockClientInformation.CANTIDAD)
                : 0);
            singleClientWithProps.LIMITE_CRED =
              this.selectedCLientCreditInformation.creditLitmit;
          } else {
            singleClientWithProps.remainingCredit =
              singleClient.LIMITE_CRED -
              singleClient.BALANCE_CRED +
              (this.unlockClientInformation &&
              this.unlockClientInformation.CANTIDAD
                ? Number(this.unlockClientInformation.CANTIDAD)
                : 0);
          }

          return singleClientWithProps;
        }
      }
      return undefined;
    },
    checkClientCanPurchase() {
      if (this.newSale.CONDICION_P === "R" && this.type === 1) {
        if (this.selectedClientInformation) {
          return (
            this.selectedClientInformation.remainingCredit >=
            this.newSale.total - this.newSale.CANTIDAD_ANTICIPO
          );
        }
        return false;
      }
      return true;
    },
    cfdiUses() {
      return this.$store.getters.SATCFDIUSES.map((singleUse) => {
        const useWithProps = { ...singleUse };
        if (useWithProps.TIPO_P === "A") {
          useWithProps.typeFormatted = "Ambos";
        } else if (useWithProps.TIPO_P === "F") {
          useWithProps.typeFormatted = "Persona física";
        } else if (useWithProps.TIPO_P === "M") {
          useWithProps.typeFormatted = "Persona moral";
        }
        return useWithProps;
      });
    },
    regimenes() {
      return this.$store.getters.SATREGIMENES.map((singleRegimen) => {
        const regimenWithProps = { ...singleRegimen };
        if (regimenWithProps.TIPO_R === "A") {
          regimenWithProps.typeFormatted = "Ambos";
        } else if (regimenWithProps.TIPO_R === "F") {
          regimenWithProps.typeFormatted = "Persona física";
        } else if (regimenWithProps.TIPO_R === "M") {
          regimenWithProps.typeFormatted = "Persona moral";
        }
        return regimenWithProps;
      });
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    userPermissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
    companyPermissions() {
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    user() {
      return this.$store.getters.USER;
    },
    users() {
      return this.$store.getters.USERS;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE" || singleEmployee.TIPO === "VC";
      });
    },
    advancedPayment() {
      return this.$store.getters.ADVANCEDPAYMENTS.map((singlePayment) => {
        const paymentWithProps = { ...singlePayment };
        return paymentWithProps;
      }).filter((singlePayment) => {
        if (
          (this.newSale.requireInvoice ||
            this.newSale.requireInvoiceAfterSend) &&
          !this.newSale.requireParcialInvoiceAfterSend
        ) {
          return (
            singlePayment.CLAVE_CLIENTE &&
            this.selectedClientInformation &&
            singlePayment.CLAVE_CLIENTE._id ===
              this.selectedClientInformation._id &&
            singlePayment.STATUS_FAC === "TI" &&
            singlePayment.RESTA > 0
          );
        } else {
          return (
            singlePayment.CLAVE_CLIENTE &&
            this.selectedClientInformation &&
            singlePayment.CLAVE_CLIENTE._id ===
              this.selectedClientInformation._id &&
            (singlePayment.STATUS_FAC === "SF" ||
              singlePayment.STATUS_FAC === "PP") &&
            singlePayment.RESTA > 0
          );
        }
      });
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    vehicules() {
      const vehiculesWithProps = [...this.$store.getters.VEHICULES];
      return vehiculesWithProps;
    },
    companyNames() {
      return this.$store.getters.COMPANY_NAMES.filter((singleCompanyName) => {
        return singleCompanyName.STATUS !== "PE";
      });
    },
  },
  watch: {
    employees() {
      if (!this.saleInformation) {
        for (const singleUser of this.users) {
          if (singleUser.email === this.user.email) {
            for (const singleEmployee of this.employees) {
              if (singleUser._id === singleEmployee.USER) {
                this.newSale.VENDEDOR = singleEmployee._id;
              }
            }
          }
        }
      }
    },
    companyInformation() {
      if (this.companyInformation.ENVIAR_MATERIAL_VENTA) {
        this.newSale.sendMaterial = true;
      }
    },
    advancedPayment() {
      if (this.type === 0) {
        // Set advanced payment information if it's a quote
        for (const singlePayment of this.advancedPayment) {
          if (singlePayment._id === this.newSale.CLAVE_ANTICIPO) {
            this.currentAdvancedPaymentInformation = singlePayment;
          }
        }
      }
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "add-sales-modal-list-columns"
        );
      },
      deep: true,
    },
  },
  filters: {
    currency(value) {
      return value.toFixed(5);
    },
    weight(value) {
      value = value.toString();
      return `${value} Kg`;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
</style>
