<template>
  <div class="container global-list-main-container">
    <div class="upper-global-hint-inner">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns-inner">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container">
        <b-field label="Filtrar por artículos" label-position="inside">
          <b-select
            placeholder="Filtrar por artículos"
            icon="account-group-outline"
            v-model="filteredArticle"
            class="global-lists-filters-control"
          >
            <option value="">Todos los artículos</option>
            <option
              v-for="article in articles"
              :value="article"
              :key="article._id"
            >
              {{ article.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de movimientos: <b>{{ filteredArticles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredArticles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      scrollable
      ref="WarehousesTable"
      :checked-rows.sync="checkedWarehouses"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      :selected.sync="selected"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_TRAN}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="CLAVE_TRAN"
    >
      <template #empty>
        <div class="has-text-centered">No hay movimientos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span>{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <p v-if="type !== '2'">No hay información que mostrar</p>
          <div class="content">
            <div class="details-controls-container-margin" v-if="type === '2'">
              <div class="controls">
                <b-button
                  v-if="checkCanConfirmTransfer(props.row)"
                  type="is-primary"
                  @click="
                    startConfirmEvent(
                      'success',
                      function () {
                        confirmTransfer(props.row);
                      },
                      'confirmar transferencia'
                    )
                  "
                  >Confirmar transferencia</b-button
                >

                <b-button
                  type="is-primary"
                  @click="
                    startCancelEvent(
                      'danger',
                      function () {
                        cancelTransfer(props.row);
                      },
                      'cancelar la transferencia'
                    )
                  "
                  >Cancelar transferencia</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import {
  openConfirmationModalSucces,
  openConfirmationModalDanger,
} from "../../../utils/component-fns";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "WarehousesTransfersList",
  props: ["warehouse", "type"],
  components: {},
  directives: { cleave },
  data() {
    //warehouses
    return {
      perPage: 50,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_TRAN",
            label: "Clave de movimiento",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FECHA_TRAN_FOR",
            label: "Fecha",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "REALIZO_MOV",
            label: "Usuario que realizó el movimiento",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "ART_CLAVE_ART",
            label: "Clave de artículo",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "ART_DESCRIPCION",
            label: "Descripción de artículo",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "STATUS_FOR",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },

          {
            field: "TIPO_FOR",
            label: "Tipo de movimiento",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "SALDO_U",
            label: "Cantidad antes del movimiento",
            sortable: true,
            searchable: true,
            display: this.type === "1" ? true : false,
          },
          {
            field: "CANTIDAD",
            label: "Cantidad movida",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "CANTIDAD_FIN",
            label: "Cantidad final",
            sortable: true,
            searchable: true,
            display: this.type === "1" ? true : false,
          },
          {
            field: "TIPO_OP",
            label: "Código de movimiento",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "OBJ_ORIGEN",
            label: "Descripción de movimiento",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "OBSER_TRAN",
            label: "Observaciones",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "ORIG_ALM",
            label: "Almacen origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "DEST_ALM",
            label: "Almacen destino",
            sortable: true,
            searchable: true,
            display: true,
          },
        ],
        "warehouses-transfers-list-columns"
      ),
      warehouseRequiredErrors: {
        CLAVE_ALMACEN: false,
        NOMBRE_ALM: false,
        DESCRIP_BREV: false,
        UBICACION: false,
        CP_ALM: false,
      },
      shippingActive: false,
      shippingTaxActive: false,
      insuranceActive: false,
      insuranceTaxActive: false,
      movementCostActive: false,
      masks: Masks,
      filteredArticle: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async confirmTransfer(row) {
      try {
        let response = await this.$store.dispatch("CONFIRMTRANSACTION", row);
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardó exitosamente la confirmación de la transferencia",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la confirmación de la transferencia en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (e) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la confirmación de la transferencia en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async cancelTransfer(row) {
      try {
        let response = await this.$store.dispatch("CANCELTRANSACTION", row);
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la transferencia",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar la transferencia en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (e) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar la transferencia en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_TRAN).getTime() || -Infinity) -
          (new Date(a.FECHA_TRAN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_TRAN).getTime() || -Infinity) -
          (new Date(b.FECHA_TRAN).getTime() || -Infinity)
        );
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETWAREHOUSES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: [],
          })
        );

        await Promise.all(allPromises);
      }
    },
    checkCanConfirmTransfer(transferInformation) {
      if (this.users.length <= 1) {
        return true;
      }

      if (this.userInformation.email !== transferInformation.REALIZO_MOV) {
        return true;
      }
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "warehouses-transfers-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        const singleArticleWithProps = { ...singleArticle };
        return singleArticleWithProps;
      }).sort((a, b) => {
        return a.DESCRIPCION > b.DESCRIPCION ? 1 : -1;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      });
    },
    movements() {
      for (const singleWarehouse of this.warehouses) {
        if (this.warehouse._id === singleWarehouse._id) {
          if (singleWarehouse.CLAVE_TRANS) {
            return singleWarehouse.CLAVE_TRANS.map((singleTransfer) => {
              let singleTransferWithProps = { ...singleTransfer };
              singleTransferWithProps.FECHA_TRAN_FOR = moment(
                singleTransferWithProps.FECHA_TRAN
              ).format("DD-MM-YYYY HH:mm");
              if (singleTransferWithProps.DESTINO) {
                singleTransferWithProps.DEST_ALM =
                  singleTransferWithProps.DESTINO.NOMBRE_ALM;
              }
              if (singleTransferWithProps.ORIGEN) {
                singleTransferWithProps.ORIG_ALM =
                  singleTransferWithProps.ORIGEN.NOMBRE_ALM;
              }
              if (singleTransferWithProps.CLAVE_ART)
                singleTransferWithProps.ART_CLAVE_ART =
                  singleTransferWithProps.CLAVE_ART.CLAVE_ART;
              if (singleTransferWithProps.CLAVE_ART)
                singleTransferWithProps.ART_DESCRIPCION =
                  singleTransferWithProps.CLAVE_ART.DESCRIPCION;
              singleTransferWithProps.TIPO_FOR = "Sin definir";
              if (singleTransferWithProps.TIPO_AC === "I") {
                singleTransferWithProps.TIPO_FOR = "Ingreso";
                singleTransferWithProps.CANTIDAD_FIN =
                  singleTransferWithProps.SALDO_U +
                  singleTransferWithProps.CANTIDAD;
              }
              if (singleTransferWithProps.TIPO_AC === "E") {
                singleTransferWithProps.TIPO_FOR = "Egreso";
                singleTransferWithProps.CANTIDAD_FIN =
                  singleTransferWithProps.SALDO_U +
                  singleTransferWithProps.CANTIDAD;
              }

              if (singleTransferWithProps.STATUS_TRA === "C") {
                singleTransferWithProps.STATUS_FOR = "Confirmada";
              } else if (singleTransferWithProps.STATUS_TRA === "CA") {
                singleTransferWithProps.STATUS_FOR = "Cancelada";
              } else if (singleTransferWithProps.STATUS_TRA === "T") {
                singleTransferWithProps.STATUS_FOR = "Pendiente";
              }
              return singleTransferWithProps;
            }).filter((singleMovement) => {
              return this.type === "1"
                ? singleMovement.STATUS_TRA === "C" ||
                    singleMovement.STATUS_TRA === "CA"
                : singleMovement.STATUS_TRA === "T";
            });
          }
        }
      }

      return [];
    },
    filteredArticles() {
      return this.movements.filter((singleMovement) => {
        let passedFiltersArticles = false;
        let passedFiltersDate = false;

        if (!this.filteredArticle) {
          passedFiltersArticles = true;
        }

        if (this.filteredArticle) {
          if (
            singleMovement.CLAVE_ART &&
            singleMovement.CLAVE_ART._id === this.filteredArticle._id
          ) {
            passedFiltersArticles = true;
          } else {
            passedFiltersArticles = false;
          }
        }

        if (this.startDate === undefined && this.endDate === undefined) {
          passedFiltersDate = true;
        } else if (this.startDate !== undefined && this.endDate === undefined) {
          // If starting date is set but not ending date, then only use starting date filter
          passedFiltersDate = moment(singleMovement.FECHA_TRAN).isSameOrAfter(
            this.startDate,
            "day"
          );
        } else if (this.startDate === undefined && this.endDate !== undefined) {
          // If ending date is set but not starting date, then only use ending date filter
          passedFiltersDate = moment(singleMovement.FECHA_TRAN).isSameOrBefore(
            this.endDate,
            "day"
          );
        } else if (this.startDate !== undefined && this.endDate !== undefined) {
          // If starting date is set and ending date also, then only use bot for date filter
          passedFiltersDate =
            moment(singleMovement.FECHA_TRAN).isSameOrAfter(
              this.startDate,
              "day"
            ) &&
            moment(singleMovement.FECHA_TRAN).isSameOrBefore(
              this.endDate,
              "day"
            );
        }

        return passedFiltersArticles && passedFiltersDate;
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    users() {
      return this.$store.getters.USERS;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
