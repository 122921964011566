<template>
  <div class="container add-global-modal-main-container">
    <b-tabs type="is-boxed" class="container-print">
      <b-tab-item
        v-for="(singlePurchaseOrder, index) in purchaseOrders"
        :label="`Pedido para ${singlePurchaseOrder.ALMACEN.CLAVE_ALMACEN}`"
        :key="index + 1432"
      >
        <div
          class="container-print"
          ref="requestOrder"
          :style="{ marginTop: '1.5rem' }"
        >
          <img
            v-if="companyInformation.LOGO_URL"
            :src="companyInformation.LOGO_URL"
            alt="Company logo"
            style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
          />
          <p class="special-margin">
            <strong>
              {{
                companyInformation.LEYENDA_SUPERIOR
                  ? companyInformation.LEYENDA_SUPERIOR
                  : ""
              }}</strong
            >
          </p>
          <table width="100%">
            <tr class="logotype-container">
              <td>
                <div
                  class="logotype-type"
                  :style="[backgroundStyle, textStyle]"
                >
                  {{ title }}
                </div>
              </td>
            </tr>
          </table>
          <br />
          <div style="display: flex">
            <div style="margin-bottom: 20px; width: 50%">
              <strong>Sucursal de Envío</strong><br />
            </div>

            <div style="margin-bottom: 20px; width: 50%; text-align: end">
              <strong>{{
                formatCreationDate(singlePurchaseOrder.creationDate)
              }}</strong
              ><br />
            </div>
          </div>
          <table width="100%">
            <tr>
              <td>
                <table>
                  <tr>
                    <td>
                      <strong>Nombre:</strong>
                      {{ companyName }} <br />
                      <strong>Unidad:</strong>
                      {{ singlePurchaseOrder.ALMACEN.DESCRIP_BREV }} <br />
                      <strong>Dirección:</strong>
                      {{ singlePurchaseOrder.ALMACEN.DOMICIL_ALM }}
                      {{ singlePurchaseOrder.ALMACEN.NUM_EXTER }}
                      {{ singlePurchaseOrder.ALMACEN.NUM_INTER }}
                      {{ singlePurchaseOrder.ALMACEN.ESTADO_ALM }},
                      {{ singlePurchaseOrder.ALMACEN.NOMBRE_MUNICIPIO }}
                      {{ singlePurchaseOrder.ALMACEN.CP_ALM }}<br />
                    </td>

                    <!--
                    <td v-if="saleInformation">
                      <strong>Nombre:</strong>
                      {{ companyName }} <br />
                      <strong>Unidad:</strong>
                      {{ saleInformation.CLAVE_ALMACEN.DESCRIP_BREV }} <br />
                      <strong>Dirección:</strong>
                      {{ saleInformation.CLAVE_ALMACEN.DOMICIL_ALM }}
                      {{ saleInformation.CLAVE_ALMACEN.NUM_EXTER }}
                      {{ saleInformation.CLAVE_ALMACEN.NUM_INTER }}
                      {{ saleInformation.CLAVE_ALMACEN.ESTADO_ALM }},
                      {{ saleInformation.CLAVE_ALMACEN.NOMBRE_MUNICIPIO }}
                      {{ saleInformation.CLAVE_ALMACEN.CP_ALM }}<br />
                    </td>
                    -->
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          <div
            style="
              background: rgba(0, 0, 0, 0.1);
              padding-left: 20px;
              padding-top: 20px;
            "
            v-if="buyerInformation"
          >
            <strong>Información del comprador</strong><br />
            <table width="100%">
              <tr>
                <td
                  style="
                    width: 50%;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    vertical-align: top;
                  "
                >
                  <strong>Nombre:</strong>
                  {{ buyerInformation.NOMBRE }} {{ buyerInformation.PATERNO }}
                  {{ buyerInformation.MATERNO }}<br />
                </td>
                <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
                  <strong>Email:</strong>
                  {{ buyerInformation.EMAIL ? buyerInformation.EMAIL : "NA"
                  }}<br />
                  <strong>Télefono:</strong>
                  {{
                    buyerInformation.TEL_MOVIL
                      ? buyerInformation.TEL_MOVIL
                      : "NA"
                  }}<br />
                </td>
              </tr>
            </table>
          </div>
          <br />
          <div
            style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
            :style="backgroundStyle"
          >
            <strong :style="textStyle">Información del proveedor</strong><br />
          </div>
          <div>
            <table width="100%">
              <tr>
                <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
                  <strong>Nombre:</strong>
                  {{ singlePurchaseOrder.PRV.NOMBRE_PRV }}<br />
                  <strong>Dirección:</strong>
                  {{ singlePurchaseOrder.PRV.DOMICIL_PRV }}
                  {{ singlePurchaseOrder.PRV.ESTADO_PRV }}
                  {{ singlePurchaseOrder.PRV.NOMBRE_MUNICIPIO_PRV }}
                  {{ singlePurchaseOrder.PRV.CP_PRV }}<br />
                </td>
                <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
                  <strong>Email:</strong>
                  {{
                    singlePurchaseOrder.PRV.EMAIL
                      ? singlePurchaseOrder.PRV.EMAIL
                      : "NA"
                  }}<br />
                  <strong>Télefono:</strong>
                  {{
                    singlePurchaseOrder.PRV.CEL_PRV
                      ? singlePurchaseOrder.PRV.CEL_PRV
                      : "NA"
                  }}<br />
                </td>
              </tr>
            </table>
          </div>

          <br />
          <div class="table-overflow">
            <table
              width="100%"
              style="border-collapse: collapse; border-bottom: 1px solid #eee"
              v-if="!printWithoutPrices"
            >
              <tr>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  #
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Clave
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Artículo
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Código de proveedor
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Ubicación
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Cantidad
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Unidad
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                  v-if="singlePurchaseOrder.ieps > 0"
                >
                  IEPS
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Costo
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Costo Final
                </td>
              </tr>
              <div
                class="display-content"
                v-for="(singleItem, index) of getPurchaseOrderItems(
                  singlePurchaseOrder.currentItems
                )"
                :key="singleItem.RENGLON"
              >
                <tr class="article-element" v-if="singleItem.article">
                  <td class="row" style="text-align: center">
                    {{ index + 1 }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{ singleItem.article.CLAVE_ART }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{
                      singleItem.PRESENTACION === "UB"
                        ? singleItem.article.DESCRIPCION
                        : singleItem.presentationName
                    }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{ getProviderCode(singleItem) }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{
                      singleItem.article.UBICACION
                        ? singleItem.article.UBICACION
                        : "Sin información"
                    }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{ singleItem.quantity }}
                  </td>
                  <td
                    class="row"
                    style="text-align: center"
                    v-if="singleItem.article.UNIDAD_EMP"
                  >
                    {{ singleItem.article.UNIDAD_EMP.DESCRIP_MED }}
                  </td>
                  <td
                    class="row"
                    style="text-align: center"
                    v-if="!singleItem.article.UNIDAD_EMP"
                  >
                    Unidad de medida base
                  </td>
                  <td
                    class="row"
                    style="text-align: center"
                    v-if="singlePurchaseOrder.ieps > 0"
                  >
                    {{ singleItem.article.CANT_IEPS }}%
                  </td>
                  <td class="row" style="text-align: center">
                    {{ singleItem.price | money("MXN", 2) }}
                  </td>
                  <td class="row" style="text-align: end">
                    {{
                      ((singleItem.price - singleItem.discountTotal) *
                        singleItem.quantity)
                        | money("MXN", 2)
                    }}
                  </td>
                </tr>
              </div>
            </table>
            <table
              width="100%"
              style="border-collapse: collapse; border-bottom: 1px solid #eee"
              v-if="printWithoutPrices"
            >
              <tr>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  #
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Clave
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Artículo
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Código de proveedor
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Ubicación
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Cantidad
                </td>
                <td
                  class="column-header"
                  style="font-size: 0.9rem; text-align: center"
                  :style="[backgroundStyle, textStyle]"
                >
                  Unidad
                </td>
              </tr>
              <div
                class="display-content"
                v-for="(singleItem, index) of singlePurchaseOrder.currentItems"
                :key="singleItem.RENGLON"
              >
                <tr class="article-element" v-if="singleItem.article">
                  <td class="row" style="text-align: center">
                    {{ index + 1 }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{ singleItem.article.CLAVE_ART }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{
                      singleItem.PRESENTACION === "UB"
                        ? singleItem.article.DESCRIPCION
                        : singleItem.presentationName
                    }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{ getProviderCode(singleItem) }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{
                      singleItem.article.UBICACION
                        ? singleItem.article.UBICACION
                        : "Sin información"
                    }}
                  </td>
                  <td class="row" style="text-align: center">
                    {{ singleItem.quantity }}
                  </td>
                  <td
                    class="row"
                    style="text-align: center"
                    v-if="singleItem.article.UNIDAD_EMP"
                  >
                    {{ singleItem.article.UNIDAD_EMP.DESCRIP_MED }}
                  </td>
                  <td
                    class="row"
                    style="text-align: center"
                    v-if="!singleItem.article.UNIDAD_EMP"
                  >
                    Unidad de medida base
                  </td>
                </tr>
              </div>
            </table>
            <br />
          </div>
          <div style="margin-bottom: 20px; font-size: 1rem">
            <strong>Peso:</strong> {{ getTotalWeight(singlePurchaseOrder) }}
            <strong>Kg</strong>
          </div>
          <div style="margin-bottom: 20px; font-size: 1rem">
            <strong>Volumen:</strong> {{ getTotalVolume(singlePurchaseOrder) }}
            <strong>M³</strong>
          </div>

          <table
            width="100%"
            style="padding: 20px; border-radius: 5px"
            v-if="!printWithoutPrices"
            class="not-cut-printing"
          >
            <tr>
              <td
                style="
                  padding-top: 1rem;
                  padding-bottom: 1rem;
                  padding-right: 2%;
                "
              >
                <table style="float: right">
                  <tr>
                    <td><strong>Indirectos:</strong></td>
                    <td style="text-align: right">
                      {{ singlePurchaseOrder.costs | money("MXN", 2) }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Descuentos:</strong></td>
                    <td style="text-align: right">
                      {{ singlePurchaseOrder.discounts | money("MXN", 2) }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Sub-total:</strong></td>
                    <td style="text-align: right">
                      {{ singlePurchaseOrder.subtotal | money("MXN", 2) }}
                    </td>
                  </tr>

                  <tr>
                    <td><strong>Costo de Flete:</strong></td>
                    <td style="text-align: right">
                      {{
                        singlePurchaseOrder.shippingQuantity | money("MXN", 2)
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>IVA:</strong></td>
                    <td style="text-align: right">
                      {{ singlePurchaseOrder.iva | money("MXN", 2) }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>IEPS:</strong></td>
                    <td style="text-align: right">
                      {{ singlePurchaseOrder.ieps | money("MXN", 2) }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Total:</strong></td>
                    <td style="text-align: right">
                      {{ singlePurchaseOrder.total | money("MXN", 2) }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div
            class="alert observations-element observations-element-printing"
            style="border-radius: 5px"
            :style="backgroundStyle"
          >
            <strong :style="textStyle">Observaciones</strong> <br />
            <br />
            <b-input
              class="not-cut-printing"
              v-if="!printing"
              type="textarea"
              v-model="observationsInformation"
            ></b-input>
            <div
              class="observations-element observations-element-printing not-cut-printing"
              style="
                background-color: white;
                padding: 0.625em;
                min-height: 120px;
              "
              v-if="printing"
            >
              <p>{{ observationsInformation }}</p>
            </div>
          </div>
          <p class="special-margin-top not-cut-printing">
            <strong>
              {{
                companyInformation.LEYENDA_INFERIOR
                  ? companyInformation.LEYENDA_INFERIOR
                  : ""
              }}</strong
            >
          </p>
          <div
            v-if="printing"
            style="margin-top: 1.5rem"
            class="not-cut-printing"
          >
            Este pdf fue impreso por coconutcontrol.com
          </div>
        </div>
        <div class="container-print">
          <div class="add-global-controls">
            <b-button
              type="is-success"
              @click="print(0, singlePurchaseOrder, index)"
              >Descargar sin precios</b-button
            >
            <b-button
              type="is-success"
              @click="print(1, singlePurchaseOrder, index)"
              >Descargar con precios</b-button
            >
            <b-button
              type="is-danger"
              @click="closeModal()"
              class="cancel-button"
              >Regresar</b-button
            >
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { EventBus } from "../../../../event-bus";
import { hexToRGB } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "PrintMultiplePurchaseOrder",
  props: [
    "purchaseOrderInformation",
    "title",
    "buyer",
    "saleInformation",
    "observationsInformation",
  ],
  components: {},
  data() {
    return {
      purchaseOrders: this.purchaseOrderInformation,
      printWithoutPrices: false,
      buyerInformation: null,
      printing: false,
    };
  },
  mounted() {
    moment.locale("es");
    if (this.buyer) {
      for (const singleEmployee of this.employees) {
        if (singleEmployee._id === this.buyer) {
          this.buyerInformation = singleEmployee;
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print(type, purchaseOrder, index) {
      this.printing = true;
      await this.createPdfHtml(type, purchaseOrder, index);
      setTimeout(() => {
        this.printWithoutPrices = false;
        this.printing = false;
      }, 1000);
    },
    async createPdfHtml(type, purchaseOrder, index) {
      if (type === 0) {
        this.printWithoutPrices = true;
      } else {
        this.printWithoutPrices = false;
      }
      let pdfOptions = {
        filename: `${this.title} ${purchaseOrder.PRV.NOMBRE_PRV} ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".not-cut-printing",
          ],
        },
        scale: 2,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
      };
      let result = await html2pdf()
        .set(pdfOptions)
        .from(this.$refs.requestOrder[index])
        .save();
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    getTotalWeight(singlePurchaseOrder) {
      let totalWeight = 0;
      for (const singleArticle of singlePurchaseOrder.currentItems) {
        if (singleArticle.article && singleArticle.article.CANT_PESO) {
          totalWeight +=
            singleArticle.quantity * singleArticle.article.CANT_PESO;
        }
      }
      return totalWeight;
    },
    getTotalVolume(singlePurchaseOrder) {
      let totalWeight = 0;
      for (const singleArticle of singlePurchaseOrder.currentItems) {
        if (singleArticle.article && singleArticle.article.CANT_VOL) {
          totalWeight +=
            singleArticle.quantity * singleArticle.article.CANT_VOL;
        }
      }
      return totalWeight;
    },
    getPurchaseOrderItems(items) {
      return items
        .map((singleItem) => {
          const singleItemWithProps = { ...singleItem };
          if (singleItem.article) {
            if (
              singleItem.PRESENTACION === "UB" &&
              singleItem.article.UNIDAD_EMP
            ) {
              singleItemWithProps.presentationName =
                singleItem.article.UNIDAD_EMP.DESCRIP_MED;
            } else if (
              singleItem.PRESENTACION === "UB" &&
              !singleItem.article.UNIDAD_EMP
            ) {
              singleItemWithProps.presentationName = "Unidad de medida base";
            } else {
              // Set presentation name
              for (const singlePresentation of singleItem.article
                .PRESENTACIONES) {
                if (singlePresentation._id === singleItem.PRESENTACION) {
                  singleItemWithProps.presentationName =
                    singlePresentation.NOMBRE;
                }
              }
            }
          }
          return singleItemWithProps;
        })
        .filter((singleItem) => {
          return singleItem.quantity > 0;
        });
    },
    getProviderCode(productInformation) {
      // Get CLAVE_ARTPRV from selected provider of article.PRECIOS_PROV
      for (const singleProviderPrice of productInformation.article
        .PRECIOS_PROV) {
        if (
          singleProviderPrice.CLAVE_PRV.toString() ===
          this.purchaseOrders[0].PRV._id.toString()
        ) {
          return singleProviderPrice.CLAVE_ARTPRV || "Sin código";
        }
      }
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "CO" || singleEmployee.TIPO === "VC";
      });
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
