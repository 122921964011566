<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Buscar artículos</h1>

    <div class="table-header table-header-border">
      Cantidad de Artículos: <b>{{ articles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="articles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="selectArticleTable"
      :checked-rows="checkedArticles"
      checkable
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="purchase-order-table row-pointer"
      scrollable
      @check-all="handleCheckAll"
      @check="handleCheck"
      @click="handleCellClick"
    >
      <template #empty>
        <div class="has-text-centered">No hay productos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          label="Clave del artículo"
          field="CLAVE_ART"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.CLAVE_ART }}</span>
        </b-table-column>

        <b-table-column
          label="Descripción del artículo"
          field="DESCRIPCION"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.DESCRIPCION }}</span>
        </b-table-column>

        <b-table-column
          label="Clave del proveedor del artículo"
          field="providerCode"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.providerCode }}</span>
        </b-table-column>

        <b-table-column
          label="Costo máximo de compra"
          field="price"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          {{ props.row.COSTO_MIN_VTA | money("MXN", 5) }}
        </b-table-column>

        <b-table-column
          label="Inventario total"
          field="totalQuantity"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          {{ props.row.totalQuantity }}
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-success" @click="selectArticles()"
        >Seleccionar artículos</b-button
      >
      <b-button type="is-danger" class="cancel-button" @click="closeModal()"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
// @ is an alias to /src
export default {
  name: "SelectMultipleArticles",
  props: ["currentArticles", "providerInformation"],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      checkedArticles: [],
      checkedAll: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async selectArticles() {
      EventBus.$emit("searchMultipleArticleSelected", {
        articles: this.checkedArticles,
      });
      this.closeModal();
    },
    handleCheckAll() {
      this.checkedAll = !this.checkedAll;
      const table = this.$refs.selectArticleTable;
      this.checkedArticles = this.checkedAll
        ? table.$data.newData.slice(0)
        : [];
    },
    handleCheck(rows, row) {
      if (row) {
        const index = this.checkedArticles
          .map((singleArticle) => singleArticle._id)
          .indexOf(row._id);
        if (index >= 0) {
          this.checkedArticles.splice(index, 1);
        } else {
          this.checkedArticles.push(row);
        }
      }
    },
    handleCellClick(row) {
      if (row) {
        const index = this.checkedArticles
          .map((singleArticle) => singleArticle._id)
          .indexOf(row._id);
        if (index >= 0) {
          this.checkedArticles.splice(index, 1);
        } else {
          this.checkedArticles.push(row);
        }
      }
    },
    articlenotFound(article) {
      for (const singleArticle of this.currentArticles) {
        if (
          singleArticle.article &&
          article._id.toString() === singleArticle.article._id.toString()
        ) {
          return true;
        }
      }

      return false;
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.totalQuantity = 0;

        // Get available items
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }

        // Add the CLAVE_ARTPRV from selected provider to article
        for (const singleProviderPrice of singleArticle.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV.toString() ===
            this.providerInformation._id.toString()
          ) {
            articleWithProps.providerCode = singleProviderPrice.CLAVE_ARTPRV
              ? singleProviderPrice.CLAVE_ARTPRV
              : "Sin información";
            break;
          }
        }

        return articleWithProps;
      }).filter((singleArticle) => {
        for (const singleProviderPrice of singleArticle.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV.toString() ===
              this.providerInformation._id.toString() &&
            (singleArticle.ACTIVO_COMPRA ||
              singleArticle.ACTIVO_COMPRA === undefined ||
              singleArticle.ACTIVO_COMPRA === null)
          ) {
            return !this.articlenotFound(singleArticle);
          }
        }
        return false;
      });
    },
  },
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
