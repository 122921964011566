import { render, staticRenderFns } from "./AddSalesModal.vue?vue&type=template&id=6c523152&scoped=true&"
import script from "./AddSalesModal.vue?vue&type=script&lang=js&"
export * from "./AddSalesModal.vue?vue&type=script&lang=js&"
import style0 from "./AddSalesModal.vue?vue&type=style&index=0&id=6c523152&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c523152",
  null
  
)

export default component.exports