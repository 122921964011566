<template>
  <div class="container global-list-main-container">
    <h1 class="add-global-modal-main-title" v-if="showTitle">
      Kardex y movimientos en almacenes
    </h1>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Almacenes: <b>{{ warehouses.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="warehouses"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="WarehousesTable"
      :checked-rows.sync="checkedWarehouses"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      :selected.sync="selected"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_ALMACEN}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="CLAVE_ALMACEN"
    >
      <template #empty>
        <div class="has-text-centered">No hay almacenes</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>

          <span>{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <div class="details-controls-container">
              <div class="controls">
                <b-tooltip
                  v-if="
                    articles.length === 0 ||
                    (warehouses.length === 0 && type !== '1')
                  "
                  label="Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos"
                  multilined
                >
                  <b-button type="is-primary" disabled
                    >Ver historial de movimientos en almacén</b-button
                  >
                </b-tooltip>
                <b-button
                  type="is-primary"
                  @click="openWarehouseMovements(props.row)"
                  v-if="warehouses.length && type === '1'"
                  >Ver historial de movimientos en almacén</b-button
                >

                <b-tooltip
                  v-if="
                    articles.length === 0 ||
                    (warehouses.length === 0 && type !== '2')
                  "
                  label="Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos"
                  multilined
                >
                  <b-button type="is-primary" disabled
                    >Ver transferencias pendientes</b-button
                  >
                </b-tooltip>
                <b-button
                  type="is-primary"
                  @click="openPendingTransfers(props.row)"
                  v-if="warehouses.length && type === '2'"
                  >Ver transferencias pendientes</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import WarehousesTransferComponentsContainer from "../views/WarehousesTransferComponentsContainer";
import moment from "moment";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "WarehousesTransfers",
  props: ["type", "showTitle"],
  components: {},
  directives: { cleave },
  data() {
    //warehouses
    return {
      perPage: 50,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ALMACEN",
          label: "Clave de almacén",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIP_BREV",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "UBICACION",
          label: "Ubicación",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CP_ALM",
          label: "Código postal",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      warehouseRequiredErrors: {
        CLAVE_ALMACEN: false,
        NOMBRE_ALM: false,
        DESCRIP_BREV: false,
        UBICACION: false,
        CP_ALM: false,
      },
      shippingActive: false,
      shippingTaxActive: false,
      insuranceActive: false,
      insuranceTaxActive: false,
      movementCostActive: false,
      masks: Masks,
    };
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETWAREHOUSES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
      })
    );

    await Promise.all(allPromises);
  },
  mounted() {},
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETWAREHOUSES", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
        })
      );
      allPromises.push(this.$store.dispatch("GETARTICLES"));

      await Promise.all(allPromises);
    },
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc Display kardex component
     */
    openWarehouseMovements(warehouse) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: WarehousesTransferComponentsContainer,
        props: {
          message: "Movimientos de ",
          warehouse: warehouse,
          type: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     * @desc Display pending transfers
     */
    openPendingTransfers(warehouse) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: WarehousesTransferComponentsContainer,
        props: {
          message: "Movimientos pendientes de ",
          warehouse: warehouse,
          type: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let singleArticleWithProps = { ...singleArticle };
        return singleArticleWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.map((singleWarehouse) => {
        let singleWarehouseWithProps = { ...singleWarehouse };
        return singleWarehouseWithProps;
      }).filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
