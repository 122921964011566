<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Ordenes de compra: <b>{{ filteredPurchaseOrder.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredPurchaseOrder"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedOrders"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="ProductsTable"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_PED}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      checkbox-position="left"
      default-sort="CLAVE_PED"
      scrollable
    >
      <template #empty>
        <div class="has-text-centered">No hay ordenes de compra</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money">{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="showPurchaseOrder(props.row)"
                  >Ver orden de compra</b-button
                >
                <b-button type="is-primary" @click="startExport(props.row)"
                  >Exportar orden de compra en csv</b-button
                >
                <b-button
                  type="is-primary"
                  @click="startGenerateReport(props.row)"
                  v-if="!showReception"
                  >Ver, descargar o enviar orden de compra</b-button
                >
                <b-button
                  type="is-primary"
                  @click="
                    startCancelEvent(
                      'danger',
                      function () {
                        cancelPurchaseOrder(props.row);
                      },
                      'cancelar la orden de compra'
                    )
                  "
                  v-if="
                    !showReception &&
                    props.row.STATUS_PED !== 'C' &&
                    props.row.STATUS_PED !== 'CA'
                  "
                  >Cancelar orden de compra</b-button
                >
                <b-button
                  type="is-primary"
                  @click="receiveOrderArticles(props.row)"
                  v-if="type !== 'received' && showReception"
                  >Nueva recepción de material</b-button
                >
                <b-button
                  type="is-primary"
                  @click="receiveOrderArticles(props.row, true)"
                  v-if="type !== 'received' && showReception"
                  >Recibir todo el material faltante</b-button
                >
                <b-button
                  type="is-primary"
                  @click="openReceiveXML(props.row)"
                  v-if="type !== 'received' && showReception"
                  >Recibir material usando XML</b-button
                >
                <b-button
                  type="is-primary"
                  @click="
                    startConfirmEvent(
                      'success',
                      function () {
                        finalMaterialReception(props.row, 'C');
                      },
                      'cambiar estatus a completa'
                    )
                  "
                  v-if="type !== 'received' && showReception"
                  >Cambiar estatus a completa</b-button
                >
                <b-button
                  type="is-primary"
                  @click="
                    startConfirmEvent(
                      'success',
                      function () {
                        finalMaterialReception(props.row, 'I');
                      },
                      'cambiar estatus a incompleta'
                    )
                  "
                  v-if="type === 'received'"
                  >Cambiar estatus a incompleta</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import ChangePurchaseOrder from "./ChangePurchaseOrder";
import PrintPurchaseOrder from "../../Global/components/print-formats/PrintPurchaseOrder";
import ReceiveOrderArticles from "./ReceiveOrderArticles";
import ReceiveXMLMaterial from "./ReceiveXMLMaterial";

import {
  openConfirmationModalSucces,
  openConfirmationModalDanger,
} from "@/utils/component-fns";

export default {
  name: "PurchaseOrdersList",
  props: ["type", "showReception", "seePrices"],
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedOrders: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_PED",
            label: `${this.type ? "Folio O.C." : "Folio"}`,
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NUM_INTERNO_PROV",
            label: "Número de pedido del proveedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "FECHA_PRE",
            label: "Fecha prevista de entrega",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "TIPO_CREACION",
            label: "Tipo de creación",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALM",
            label: "Almacén",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_PRV",
            label: "Proveedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "EMAIL_GUA",
            label: "Usuario creador",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "EMAIL_ACT",
            label: "Usuario de ultima edición",
            sortable: true,
            searchable: true,
            display: false,
          },
          {
            field: "STATUS_FOR",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedCreationDate",
            label: "Fecha de la orden de compra",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
        ],
        "purchase-oders-list-columns"
      ),
      filterWarehouses: [],
      filterProvider: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  mounted() {
    if (this.type === "received" || this.type === "pending") {
      this.columns.push({
        field: "IMPRESO",
        label: "Veces descargada o enviada",
        sortable: true,
        searchable: true,
        display: false,
      });
    } else {
      this.columns.push(
        {
          field: "SUBTOTAL_PED",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "IVA_PED",
          label: "Iva total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "TOTAL_PED",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Restante de recepción",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "IMPRESO",
          label: "Veces descargada o enviada",
          sortable: true,
          searchable: true,
          display: false,
        }
      );
    }

    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(
        this.$store.dispatch("GETPURCHASEORDERS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      }
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      if (this.$store.getters.PAYMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      }
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    confirmDeletePurchaseOrders() {
      if (this.checkedOrders.length > 0) {
        this.$buefy.dialog.confirm({
          title: "Borrar proveedores",
          message:
            "¿Estas seguro que deseas <b>borrar</b> las ordenes seleccionadas? Esta acción no puede ser cambiada.",
          confirmText: "Borrar proveedores",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("DELETEPURCHASEORDERS", this.checkedOrders)
              .then((response) => {
                if (response === "Terminado") {
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message:
                      "Se borraron exitosamente las ordenes de compra seleccionadas",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al borrar las ordenes de compra en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              }),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay ordenes de compra seleccionadas",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    formatPurchaseOrder(purchaseOrder) {
      let currentItems = [];
      for (let singleArticle of purchaseOrder.PEDIDO_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          quantity: singleArticle.CANTIDAD_PE,
          quantityReceived: singleArticle.CANTIDAD_RE
            ? singleArticle.CANTIDAD_RE
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_PR || singleArticle.CANTIDAD_PR === 0
              ? singleArticle.CANTIDAD_PR
              : singleArticle.CANTIDAD_PE,
          quantityReceivedOriginal: singleArticle.CANTIDAD_RE
            ? singleArticle.CANTIDAD_RE
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_PR || singleArticle.CANTIDAD_PR === 0
              ? singleArticle.CANTIDAD_PR
              : singleArticle.CANTIDAD_PE,
          newReception: 0,
          RENGLON: singleArticle.RENGLON,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          price: singleArticle.COSTO_UNI,
          costTotal: singleArticle.COSTO_PAR,
          discountTotal: singleArticle.DESCTO_PAR,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          total: singleArticle.TOTAL,
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          PRECIO_ORIGINAL: singleArticle.PRECIO_ORIGINAL,
          MULTIPLO_COMPRA: singleArticle.MULTIPLO_COMPRA,
          PRESENTACION: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
        });
      }
      let newPurchaseOrder = {
        _id: purchaseOrder._id,
        CLAVE_PED: purchaseOrder.CLAVE_PED,
        PRV: purchaseOrder.CLAVE_PRV,
        COSTOS: purchaseOrder.COSTOS ? purchaseOrder.COSTOS : [],
        DESCS: purchaseOrder.DESCS ? purchaseOrder.DESCS : [],
        ALMACEN: purchaseOrder.CLAVE_ALMACEN,
        OBSERVA_PED: purchaseOrder.OBSERVA_PED,
        STATUS_PED: purchaseOrder.STATUS_PED,
        currentItems: currentItems,
        creationDate: purchaseOrder.FECHA_PED,
        subtotal: purchaseOrder.SUBTOTAL_PED,
        total: purchaseOrder.TOTAL_PED,
        discounts: purchaseOrder.DESCS_GTO ? purchaseOrder.DESCS_GTO : 0,
        costs: purchaseOrder.COSTOS_GTO ? purchaseOrder.COSTOS_GTO : 0,
        iva: purchaseOrder.IVA_PED,
        ieps: purchaseOrder.IEPS,
        EXENTO: purchaseOrder.EXENTO,
        shippingQuantity: purchaseOrder.FLETE_PED,
        shippingPercentage: purchaseOrder.PORC_FLETEP,
        printTimes: purchaseOrder.IMPRESO,
        COMPRADOR: purchaseOrder.COMPRADOR,
        RESTA: purchaseOrder.RESTA,
        EMAIL_GUA: purchaseOrder.EMAIL_GUA,
        EMAIL_ACT: purchaseOrder.email,
        NUM_INTERNO: purchaseOrder.NUM_INTERNO,
        NUM_INTERNO_PROV: purchaseOrder.NUM_INTERNO_PROV,
        FECHA_PRE: purchaseOrder.FECHA_PRE,
        VENTA: purchaseOrder.VENTA,
        TIPO_CREACION: purchaseOrder.creationType,
      };
      return newPurchaseOrder;
    },
    showPurchaseOrder(purchaseOrder) {
      let newPurchaseOrder = this.formatPurchaseOrder(purchaseOrder);
      let startingDateFormated = purchaseOrder.FECHA_PED;
      let creationDate = purchaseOrder.FECHA_PED;
      let currentIndex =
        Number(
          newPurchaseOrder.currentItems[
            newPurchaseOrder.currentItems.length - 1
          ].RENGLON
        ) + 1;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ChangePurchaseOrder,
        props: {
          newPurchaseOrderProp: newPurchaseOrder,
          creationDateProp: new Date(creationDate),
          startingDateFormatedProp: startingDateFormated,
          currentIndexProp: currentIndex,
          type: purchaseOrder.STATUS_PED === "P" ? "0" : "1",
          blockEditGeneralValues: true,
          typeName: `Orden de compra #${purchaseOrder.CLAVE_PED}`,
          showReception: this.seePrices ? this.seePrices : this.showReception,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async cancelPurchaseOrder(purchaseOrder) {
      try {
        let response = await this.$store.dispatch("CANCELPURCHASEORDER", {
          purchaseOrderId: purchaseOrder._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la orden de compras",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar la orden de compra en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar la orden de compras en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    startGenerateReport(purchaseOrder) {
      let newPurchaseOrder = this.formatPurchaseOrder(purchaseOrder);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintPurchaseOrder,
        props: {
          canConfirm: false,
          title: "Orden de compra",
          purchaseOrderInformation: newPurchaseOrder,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openReceiveXML(purchaseOrder) {
      let newPurchaseOrder = this.formatPurchaseOrder(purchaseOrder);
      let startingDateFormated = purchaseOrder.FECHA_PED;
      let creationDate = purchaseOrder.FECHA_PED;
      let currentIndex = newPurchaseOrder.currentItems.length + 1;

      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ReceiveXMLMaterial,
        props: {
          newPurchaseOrderProp: newPurchaseOrder,
          creationDateProp: new Date(creationDate),
          startingDateFormatedProp: startingDateFormated,
          currentIndexProp: currentIndex,
          reveiveFullOrder: false,
          purchaseOrderInformation: purchaseOrder,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    receiveOrderArticles(purchaseOrder, receiveFullOrden = false) {
      let newPurchaseOrder = this.formatPurchaseOrder(purchaseOrder);
      let startingDateFormated = purchaseOrder.FECHA_PED;
      let creationDate = purchaseOrder.FECHA_PED;
      let currentIndex = newPurchaseOrder.currentItems.length + 1;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ReceiveOrderArticles,
        props: {
          newPurchaseOrderProp: newPurchaseOrder,
          creationDateProp: new Date(creationDate),
          startingDateFormatedProp: startingDateFormated,
          currentIndexProp: currentIndex,
          reveiveFullOrder: receiveFullOrden,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async finalMaterialReception(purchaseOrder, type) {
      try {
        let response = await this.$store.dispatch("RECEIVEALLMATERIAL", {
          purchaseOrderId: purchaseOrder._id,
          type,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardó exitosamente el nuevo estatus de recepción de material de la orden de compras",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el nuevo estatus de recepción de material de la orden de compras en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el nuevo estatus de recepción de material de la orden de compras en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PED).getTime() || -Infinity) -
          (new Date(a.FECHA_PED).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PED).getTime() || -Infinity) -
          (new Date(b.FECHA_PED).getTime() || -Infinity)
        );
      }
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETPURCHASEORDERS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
    async startExport(ordenInformation) {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de orden de compra",
        message: `¿Desea confirmar la exportación de la orden de compra a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          this.exportData(ordenInformation);
        },
      });
    },
    exportData(orderInformation) {
      let newPurchaseOrder = this.formatPurchaseOrder(orderInformation);
      const orderDetailsFormatted = newPurchaseOrder.currentItems.map(
        (singleItem) => {
          const singleItemWithProps = { ...singleItem };
          if (singleItem.article) {
            if (
              singleItem.PRESENTACION === "UB" &&
              singleItem.article.UNIDAD_EMP
            ) {
              singleItemWithProps.presentationName =
                singleItem.article.UNIDAD_EMP.DESCRIP_MED;
            } else if (
              singleItem.PRESENTACION === "UB" &&
              !singleItem.article.UNIDAD_EMP
            ) {
              singleItemWithProps.presentationName = "Unidad de medida base";
            } else {
              // Set presentation name
              for (const singlePresentation of singleItem.article
                .PRESENTACIONES) {
                if (singlePresentation._id === singleItem.PRESENTACION) {
                  singleItemWithProps.presentationName =
                    singlePresentation.NOMBRE;
                }
              }
            }
          }
          return singleItemWithProps;
        }
      );

      let orderFormatted = [];

      for (const orderDetailFormatted of orderDetailsFormatted) {
        let exportDetail = {};

        exportDetail["CLAVE"] = orderDetailFormatted.article.CLAVE_ART;

        exportDetail["CLAVE DEL ARTÍCULO DEL PROVEEDOR"] = "";
        for (const providerPrice of orderDetailFormatted.article.PRECIOS_PROV) {
          if (
            providerPrice.CLAVE_PRV.toString() ===
            orderInformation.CLAVE_PRV._id.toString()
          ) {
            exportDetail["CLAVE DEL ARTÍCULO DEL PROVEEDOR"] =
              providerPrice.CLAVE_ARTPRV;
          }
        }

        exportDetail["ARTICULO"] =
          orderDetailFormatted.PRESENTACION === "UB"
            ? orderDetailFormatted.article.DESCRIPCION
            : orderDetailFormatted.presentationName;

        exportDetail["UBICACION"] = "";
        exportDetail["UBICACION"] = orderDetailFormatted.article.UBICACION;

        exportDetail["CANTIDAD"] = orderDetailFormatted.quantity;

        exportDetail["UNIDAD"] = orderDetailFormatted.article.UNIDAD_EMP
          ? orderDetailFormatted.article.UNIDAD_EMP.DESCRIP_MED
          : "Unidad de medida base";

        exportDetail["COSTO"] = orderDetailFormatted.price;

        exportDetail["COSTO FINAL"] =
          (orderDetailFormatted.price - orderDetailFormatted.discountTotal) *
          orderDetailFormatted.quantity;

        orderFormatted.push(exportDetail);
      }

      let indirectosTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "INDIRECTOS",
        "CANTIDAD TOTALES": newPurchaseOrder.costs ? newPurchaseOrder.costs : 0,
      };

      let descuentosTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "DESCUENTOS",
        "CANTIDAD TOTALES": newPurchaseOrder.discounts
          ? newPurchaseOrder.discounts
          : 0,
      };

      let subTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "SUB TOTAL",
        "CANTIDAD TOTALES": newPurchaseOrder.subtotal
          ? newPurchaseOrder.subtotal
          : 0,
      };

      let fleteTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "COSTO DE FLETE",
        "CANTIDAD TOTALES": newPurchaseOrder.shippingQuantity
          ? newPurchaseOrder.shippingQuantity
          : 0,
      };

      let ivaTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "IVA",
        "CANTIDAD TOTALES": newPurchaseOrder.iva ? newPurchaseOrder.iva : 0,
      };

      let iepsTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "IEPS",
        "CANTIDAD TOTALES": newPurchaseOrder.ieps ? newPurchaseOrder.ieps : 0,
      };

      let totalTotalsObject = {
        "": "",
        "DESCRIPCION TOTALES": "TOTAL",
        "CANTIDAD TOTALES": newPurchaseOrder.total ? newPurchaseOrder.total : 0,
      };

      orderFormatted.push(
        indirectosTotalsObject,
        descuentosTotalsObject,
        subTotalsObject,
        fleteTotalsObject,
        ivaTotalsObject,
        iepsTotalsObject,
        totalTotalsObject
      );

      let payload = {
        data: orderFormatted,
        name: "orden-de-compra-" + orderInformation.CLAVE_PED,
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
  },
  async created() {
    moment.locale("es");
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
    columns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "purchase-oders-list-columns"
        );
      },
      deep: true,
    },
  },
  computed: {
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    requestOrders() {
      return this.$store.getters.REQUESTORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        purchaseOrderWithProps.NOMBRE_ALM =
          singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM;
        purchaseOrderWithProps.NOMBRE_PRV =
          singlePurchaseOrder.CLAVE_PRV.NOMBRE_PRV;
        purchaseOrderWithProps.formatedCreationDate = moment(
          singlePurchaseOrder.FECHA_PED
        ).format("DD-MM-YYYY HH:mm");
        return purchaseOrderWithProps;
      });
    },
    purchaseOrders() {
      return this.$store.getters.PURCHASEORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        purchaseOrderWithProps.NOMBRE_ALM =
          singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM;
        purchaseOrderWithProps.NOMBRE_PRV =
          singlePurchaseOrder.CLAVE_PRV.NOMBRE_PRV;
        purchaseOrderWithProps.formatedCreationDate = moment(
          singlePurchaseOrder.FECHA_PED
        ).format("DD-MM-YYYY HH:mm");
        if (singlePurchaseOrder.STATUS_PED === "P") {
          purchaseOrderWithProps.STATUS_FOR = "Pendiente";
        } else if (singlePurchaseOrder.STATUS_PED === "C") {
          purchaseOrderWithProps.STATUS_FOR = "Completa";
        } else if (singlePurchaseOrder.STATUS_PED === "I") {
          purchaseOrderWithProps.STATUS_FOR = "Incompleta";
        } else if (singlePurchaseOrder.STATUS_PED === "CA") {
          purchaseOrderWithProps.STATUS_FOR = "Cancelada";
        }
        return purchaseOrderWithProps;
      }).filter((singlePurchaseOrder) => {
        if (this.type === "received") {
          return singlePurchaseOrder.STATUS_PED
            ? singlePurchaseOrder.STATUS_PED === "C"
            : false;
        } else if (this.type === "pending") {
          return singlePurchaseOrder.STATUS_PED
            ? singlePurchaseOrder.STATUS_PED === "P" ||
                singlePurchaseOrder.STATUS_PED === "I"
            : true;
        } else {
          return true;
        }
      });
    },
    filteredPurchaseOrder() {
      if (this.filterProvider === "") {
        return this.purchaseOrders.map((singlePurchaseOrder) => {
          let singlePurchaseOrderWithProps = { ...singlePurchaseOrder };
          return singlePurchaseOrderWithProps;
        });
      }
      return this.purchaseOrders.filter((singlePurchaseOrder) => {
        if (singlePurchaseOrder.CLAVE_PRV._id === this.filterProvider._id) {
          return true;
        }
        return false;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
